<template>
  <header :class="{ iframe: isIframe }">
    <LogoIcon v-if="isLogo" class="logo" />
    <div class="buttons">
      <div class="prev-button" v-if="isPrevButton" @click="prevStepHandler">
        <IconArrowButton color="#F18E35" />
      </div>
      <VButton
        v-if="isNextButton"
        class="button"
        @click="nextStepHandler"
        :disabled="isDisabled"
      >
        <template #rightAddon>
          <IconArrowButton :color="isDisabled ? '#858585' : '#ffffff'" />
        </template>
        <template #default>Далее</template>
      </VButton>
    </div>
    <IconWidgetCloseCross v-if="isIframe" @click="closeWidget" class="cross" />
  </header>
</template>

<script>
import LogoIcon from "@/components/icons/LogoIcon.vue";
import VButton from "@/components/ui/VButton.vue";
import IconArrowButton from "@/components/icons/IconArrowButton.vue";
import IconWidgetCloseCross from "@/components/icons/IconWidgetCloseCross.vue";

export default {
  name: "HeaderWidget",
  components: { IconWidgetCloseCross, IconArrowButton, VButton, LogoIcon },
  data() {
    return {
      isHovered: false,
    };
  },
  methods: {
    closeWidget() {
      parent.postMessage("closeIframe", "*");
    },
    nextStepHandler() {
      if (this.$store.state.booking_master.step === 6) {
        this.$store.commit({
          type: "setIsDetailsSent",
          value: true,
        });
      }
      this.$store.commit({
        type: "setStep",
        value: this.$store.state.booking_master.step + 1,
      });
    },
    prevStepHandler() {
      if (this.$store.state.booking_master.step === 8) {
        this.$store.commit({
          type: "setNextButtonStatusDisabled",
          value: false,
        });
        this.$store.commit({
          type: "setStep",
          value: 3,
        });
        return;
      }
      this.$store.commit({
        type: "setStep",
        value: this.$store.state.booking_master.step - 1,
      });
    },
  },
  computed: {
    isDisabled() {
      return this.$store.state.booking_master.isNextButtonDisabled;
    },
    isLogo() {
      return this.$store.state.booking_master.step !== 5;
    },
    isNextButton() {
      return (
        this.$store.state.booking_master.step !== 0 &&
        this.$store.state.booking_master.step !== 7 &&
        this.$store.state.booking_master.step !== 9
      );
    },
    isPrevButton() {
      return (
        this.$store.state.booking_master.step !== 0 &&
        this.$store.state.booking_master.step !== 5 &&
        this.$store.state.booking_master.step !== 9
      );
    },
    isIframe() {
      return window.self !== window.top;
    },
  },
};
</script>

<style scoped lang="scss">
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 88px;
  padding: 20px 24px 20px;
  background: #ffffff;
  border-bottom: 1px solid #ebebeb;

  &.iframe {
    padding-left: 88px;
    border-bottom: none;
  }

  @include mobile {
    height: 68px;
    padding: 14px 16px 14px 64px;
  }
}

.logo {
  width: 70px;
  height: 40px;

  @include mobile {
    width: 56px;
    height: 32px;
  }
}

.button {
  width: 104px;
  border-radius: 100px;
  padding: 10px 10px 10px 24px;
  height: 44px;
  cursor: pointer;

  @include mobile {
    height: 40px;
    padding: 8px 8px 8px 16px;
  }
}

.prev-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid #f18e35;
  cursor: pointer;

  svg {
    transform: rotate(180deg);
  }

  @include mobile {
    width: 40px;
    height: 40px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-left: auto;
  z-index: 1000000;
  background: transparent;
}

.cross {
  position: absolute;
  top: 16px;
  left: 16px;
  cursor: pointer;
  z-index: 1;
}
</style>
