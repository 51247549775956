<template>
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.3077 17.4998C2.80898 17.4998 2.38302 17.3232 2.02982 16.97C1.67661 16.6168 1.5 16.1908 1.5 15.6921V2.99981H1.24999C0.835781 2.99981 0.5 2.66403 0.5 2.24982C0.5 1.83562 0.835781 1.49983 1.24999 1.49983H4.99997C4.99997 1.01128 5.39602 0.615234 5.88457 0.615234H10.1154C10.6039 0.615234 11 1.01128 11 1.49983H14.75C15.1642 1.49983 15.5 1.83562 15.5 2.24982C15.5 2.66403 15.1642 2.99981 14.75 2.99981H14.5V15.6921C14.5 16.1972 14.325 16.6248 13.975 16.9748C13.625 17.3248 13.1974 17.4998 12.6922 17.4998H3.3077ZM13 2.99981H2.99997V15.6921C2.99997 15.7818 3.02883 15.8556 3.08652 15.9133C3.14422 15.971 3.21795 15.9998 3.3077 15.9998H12.6922C12.7692 15.9998 12.8397 15.9678 12.9038 15.9037C12.9679 15.8395 13 15.769 13 15.6921V2.99981ZM5.40385 13.2498C5.40385 13.664 5.73963 13.9998 6.15384 13.9998C6.56804 13.9998 6.90382 13.664 6.90382 13.2498V5.7498C6.90382 5.33559 6.56804 4.99981 6.15384 4.99981C5.73963 4.99981 5.40385 5.33559 5.40385 5.7498V13.2498ZM9.09613 13.2498C9.09613 13.664 9.43191 13.9998 9.84611 13.9998C10.2603 13.9998 10.5961 13.664 10.5961 13.2498V5.74979C10.5961 5.33559 10.2603 4.99981 9.84611 4.99981C9.43191 4.99981 9.09613 5.33559 9.09613 5.7498V13.2498Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconDeleteCan",
  props: {
    color: {
      type: String,
      default: "#E3312D",
    },
  },
};
</script>

<style scoped></style>
