<template>
  <div class="slots" :class="{ _sidebar: sidebar }">
    <div class="hours-wrapper" :class="{ _widget: isWidget }">
      <perfect-scrollbar class="hours" ref="hours">
        <span
          v-for="(value, key, index) in hours"
          class="hour"
          ref="hour"
          :class="{
            _active: key === currentHour && typeHour === 'current',
            _disabled: !isFreeHour(value),
          }"
          :key="index"
          @click="onHourClickHandler(key, value, 'current')"
        >
          {{ key }}
        </span>
        <span
          v-for="(value, key, index) in hoursNext"
          class="hour"
          ref="hour"
          :class="{
            _active: key === currentHour && typeHour === 'next',
            _disabled: !isFreeHour(value),
          }"
          :key="index"
          @click="onHourClickHandler(key, value, 'next')"
        >
          {{ key }}
        </span>
      </perfect-scrollbar>
    </div>

    <div class="minutes">
      <IconBookingSelectArrow
        v-if="currentHourIndex !== 0"
        class="arrow"
        @click="changeHour(-1)"
      />
      <IconBookingSelectArrow
        v-if="currentHourIndex !== Object.keys(this.hours).length - 1"
        class="arrow _bottom"
        @click="changeHour(1)"
      />
      <div class="minutes-slots">
        <div
          v-for="(minute, index) in minutes"
          class="minutes-slot"
          :class="{
            _disabled: !minute || minute === 2,
            _active: index === currentMinute,
          }"
          :key="index"
          @click="onMinuteClickHandler(minute, index, !minute || minute === 2)"
        >
          {{ getMinute(index) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBookingSelectArrow from "@/components/icons/booking/IconBookingSelectArrow.vue";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";

export default {
  name: "TimeSlots",

  components: {
    IconBookingSelectArrow,
    PerfectScrollbar,
  },

  props: {
    timeSlots: {
      type: Array,
    },
    currentHour: {
      type: String,
    },
    currentMinute: {
      type: String,
    },
    sidebar: {
      type: Boolean,
    },
    isWidget: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hours: {},
      hoursNext: {},
      freeHours: {},
      newTimeSlots: [],
      currentHourIndex: 0,
      typeHour: "",
    };
  },

  methods: {
    changeHour(val) {
      const index = this.currentHourIndex + val;
      this.$emit("updateHour", Object.keys(this.hours)[index]);
    },
    onHourClickHandler(key, value, type) {
      if (value) {
        this.typeHour = type;
        this.$emit("updateHour", key);
      }
    },
    onMinuteClickHandler(minute, index, isDisabled) {
      if (isDisabled) {
        return;
      }

      if (minute) {
        this.$emit("updateMinute", index);
      }
    },
    getMinute(index) {
      return `${this.currentHour.slice(0, 2).replace("-", "")}:${
        index === 0 ? "00" : index * 10
      }`;
    },
    isFreeHour(val) {
      if (val) {
        return Boolean(
          val.filter((el) => el !== 0).filter((el) => el !== 2).length
        );
      } else {
        return true;
      }
    },
  },

  watch: {
    typeHour(val) {
      if (val === "current") {
        this.$emit("isDateSlot", false);
      } else {
        this.$emit("isDateSlot", true);
      }
    },
    currentHour(val) {
      this.$emit("updateMinuteWithoutAction", "");

      function isHour(element) {
        return element === val;
      }

      this.currentHourIndex = Object.keys(this.hours).findIndex(isHour);

      setTimeout(() => {
        if (this.$refs.hour) {
          const indexHour = Array.from(this.$refs.hour).findIndex((el) => {
            const elementsArr = Array.from(el.classList);

            if (elementsArr.includes("_active")) {
              return el;
            }
          });

          if (indexHour < 3) {
            this.$refs.hours.$el.scrollTo({ top: 0 });
          } else {
            this.$refs.hours.$el.scrollTo({
              top: (indexHour - 2) * 64,
            });
          }
        }
      });
    },
    timeSlots: {
      handler(val) {
        const objKeys = Object.keys(val).sort(function (a, b) {
          return (
            +a.replace("-", "").replace("-", "") -
            +b.replace("-", "").replace("-", "")
          );
        });

        let hoursNext = {};

        let isZero = false;

        for (let key in val[objKeys[1]]) {
          if (key !== "00-00") {
            hoursNext[key] = val[objKeys[1]][key];
          } else {
            isZero = true;
          }
        }

        if (isZero) {
          hoursNext = {};

          for (let key in val[objKeys[1]]) {
            if (key === "00-00") {
              hoursNext[key] = val[objKeys[1]]["00-00"];
            }
          }

          for (let key in val[objKeys[1]]) {
            if (key !== "00-00") {
              hoursNext[key] = val[objKeys[1]][key];
            }
          }
        }

        this.hours = val[objKeys[0]];

        this.hoursNext = hoursNext;

        if (!this.currentHour) {
          for (const key in this.hours) {
            if (this.isFreeHour(this.hours[key])) {
              this.$emit("updateHour", key);
              this.typeHour = "current";
              break;
            }
          }

          if (!this.typeHour) {
            for (const key in this.hoursNext) {
              if (this.isFreeHour(this.hoursNext[key])) {
                this.$emit("updateHour", key);
                this.typeHour = "next";
                break;
              }
            }
          }
        } else {
          this.typeHour = "current";
        }

        this.$nextTick(() => {
          const lastHour =
            this.$refs.hour[Array.from(this.$refs.hour).length - 1];
          lastHour.style.marginBottom = 0;
        });
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    minutes() {
      if (this.typeHour === "next") {
        return this.hoursNext[this.currentHour];
      } else {
        return this.hours[this.currentHour];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slots {
  display: flex;
  justify-content: center;
  scroll-behavior: smooth;
  overflow: auto;

  &._sidebar {
    margin-top: 16px;
    margin-bottom: 24px;
    overflow: visible;

    .minutes {
      &-slot {
        width: 132px;
      }
    }
  }
}

.hours {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 172px;
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 0 0 auto;

  @include mobile {
    width: 98px;
  }
}

.hours-wrapper {
  padding: 42px 0;
  height: 320px;
  margin-right: 36px;

  &._widget {
    height: 370px;

    @include mobile {
      height: 272px;
      width: 98px;
      margin-right: 6px;
      padding: 0;
    }
  }

  @include mobile {
    height: 272px;
    width: 98px;
    margin-right: 6px;
    padding: 0;
  }
}

.hour {
  color: #adadad;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  cursor: pointer;
  user-select: none;
  transition: 0.5s;

  @include mobile {
    font-size: 24px;
    line-height: 32px;
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  &._active {
    font-family: $font-family-secondary;
    color: #f18e35;
    font-size: 56px;
    font-style: normal;
    font-weight: 400;
    line-height: 64px;
    transition: 0.5s;

    @include mobile {
      font-size: 32px;
      font-weight: 400;
      line-height: 32px;
    }
  }

  &._disabled {
    font-size: 24px;
    color: #f79b9b;
    cursor: auto;
  }
}

.minutes {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  &._widget {
    .minutes-slots {
      gap: 12px;
      height: 162px;
    }
  }

  width: 290px;

  @include mobile {
    gap: 8px;
    width: 224px;
  }

  &-slot {
    display: flex;
    height: 46px;
    border-radius: 60px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    cursor: pointer;
    border: 1px solid #ebebeb;

    color: #222222;
    font-weight: 400;
    line-height: 24px;

    @include mobile {
      width: calc(50% - 4px);
      min-width: 108px;
    }

    &._active {
      background-color: #f18e35;
      color: #ffffff;

      text-align: center;
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
      border: none;
    }

    &._disabled {
      cursor: auto;
      background-color: #fdebeb;
      border: none;
    }
  }
}
.arrow {
  position: absolute;
  top: 0;
  left: 50%;
  cursor: pointer;
  transform: translateX(-50%);

  &._bottom {
    bottom: 0;
    top: auto;
    transform: rotate(180deg) translateX(50%);
  }
}

.ps__rail-y {
  left: 0;
  right: auto;
}

.minutes-slots {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  width: 100%;
  height: 154px;
  max-width: 400px;
  margin: 0 auto;
}
</style>
