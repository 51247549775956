import store from "@/store";

export default async (to, from, next) => {
  if (!from.name) {
    await store.dispatch("checkAuth");
  }

  const isLoggedIn = store.state.general.auth.isAuth;

  let exceptionalRoutes = [
    "home",
    "auth-eviso",
    "auth-biz",
    "booking",
    "home/:catId",
    "booking/:catId",
    "restaurants",
    "restaurant",
    "widget",
  ];
  let isGoingExceptionalRoutes = exceptionalRoutes.includes(to.name);

  if (!isLoggedIn) {
    if (isGoingExceptionalRoutes) {
      next();
    } else {
      if (to.name === "account/catering/id") {
        next(`/${to.params.id}`);
      } else {
        next({ name: "404" });
      }
    }
  } else {
    next();
  }
};
