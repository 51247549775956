<template>
  <div :class="['dropdown', classList]">
    <div
      ref="input"
      class="dropdown__field"
      tabindex="-1"
      @focus="onFocus"
      @keydown.down.stop.prevent="navigateOptions('down')"
      @keydown.up.stop.prevent="navigateOptions('up')"
      @keydown.enter.prevent="onEnterPress"
      @keydown.esc="isOpened = false"
      @keydown.tab="onBlur"
      @click.stop="toggleMenu"
    >
      <div class="dropdown__input">{{ selectedLabel }}</div>

      <div class="dropdown__arrow">
        <IconSelectArrow class="purchase-detail__arrow" color="#F18E35" />
      </div>

      <span class="label">{{ label }}</span>
    </div>

    <span v-if="required" class="dropdown__required">*обязательное поле</span>

    <transition name="menu">
      <div
        v-click-outside="onClickOutside"
        v-if="isOpened"
        ref="menu"
        class="dropdown__menu"
      >
        <div class="dropdown__scrollbox">
          <div
            v-for="(option, index) in options"
            :key="option.value"
            class="dropdown__option"
            :class="{
              _selected: option.value === value,
              _highlighted: highlightIndex === index,
            }"
            @mouseenter="highlightIndex = index"
            @mouseleave="highlightIndex = -1"
            @click="onOptionSelect(option.value)"
          >
            {{ option.label }}
            <AcceptModalButton
              acceptButtonText="Удалить"
              cancelButtonText="Отменить"
              title="Удалить этаж?"
              text="Вы уверены, что хотите удалить этаж. Восстановить данные будет невозможно."
              @accept="deleteFloor(index)"
              v-slot="scope"
            >
              <IconDeleteCan
                v-if="index !== 0 && !isClient"
                @click.stop="scope.open"
              />
            </AcceptModalButton>
          </div>
        </div>
        <VButton
          v-if="floorsLength < 4 && !isClient"
          class="dropdown__button"
          @click="addFloor"
        >
          <template #leftAddon><IconPlus /></template>
          <template #default>Добавить этаж</template>
        </VButton>
      </div>
    </transition>
  </div>
</template>

<script>
import IconSelectArrow from "@/components/icons/IconSelectArrow";
import VButton from "@/components/ui/VButton.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import IconDeleteCan from "@/components/icons/IconDeleteCan.vue";
import AcceptModalButton from "@/components/modals/AcceptModalButton.vue";

export default {
  name: "BookingDropdown",

  components: {
    AcceptModalButton,
    IconDeleteCan,
    IconPlus,
    VButton,
    IconSelectArrow,
  },

  emits: ["update:value"],

  props: {
    value: {
      type: [String, Number],
      required: true,
    },

    options: {
      type: Array,
      default: () => [],
    },

    color: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    size: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "s",
      validator: (value) => ["l", "m", "s"].includes(value),
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    isClient: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    large: {
      type: Boolean,
      default: false,
    },

    dummyLabel: {
      type: String,
      default: "",
    },

    floorsLength: {
      type: Number,
      default: 1,
    },

    error: Boolean,
  },

  data() {
    return {
      isFocused: false,
      isOpened: false,
      highlightIndex: -1,
    };
  },

  computed: {
    classList() {
      return {
        [`dropdown-${this.size}`]: this.size,

        _focused: this.isFocused,
        _opened: this.isOpened,
        _disabled: this.isDisabled,
        _error: this.error,
        _selected: this.selectedLabel,
        _label: this.label,
        _large: this.large,
      };
    },

    selectedOption() {
      if (this.value && this.options && this.options.length) {
        const selected = this.options.filter((opt) =>
          opt.value ? opt.value === this.value : opt === this.value
        )[0];
        if (!selected) {
          console.warn("Error occured while selected computed");
        }
        return selected;
      } else {
        return null;
      }
    },

    selectedLabel() {
      if (this.value && this.selectedOption) {
        return this.selectedOption.label
          ? this.selectedOption.label
          : this.selectedOption;
      } else {
        return this.placeholder || "Выберите";
      }
    },

    isDisabled() {
      return this.disabled || this.options.length === 0;
    },
  },

  methods: {
    deleteFloor(index) {
      this.$store.commit({
        type: "deleteFloor",
        value: index,
      });
    },
    addFloor() {
      this.$store.commit({
        type: "addFloor",
      });
    },
    navigateOptions(direction) {
      if (!this.isOpened) {
        this.isOpened = true;
        return;
      }
      if (direction === "down") {
        this.highlightIndex++;
        if (this.highlightIndex === this.options.length) {
          this.highlightIndex = 0;
        }
      } else if (direction === "up") {
        this.highlightIndex--;
        if (this.highlightIndex < 0) {
          this.highlightIndex = this.options.length - 1;
        }
      }
      const option = this.options[this.highlightIndex];
      if (option.disabled) {
        this.navigateOptions(direction);
      }
    },

    toggleMenu() {
      if (this.isDisabled) {
        return;
      }
      this.isOpened = !this.isOpened;
      if (this.isOpened) {
        this.$refs.input.focus();
      }
    },

    onEnterPress() {
      if (!this.isOpened) {
        this.toggleMenu();
      } else if (this.options[this.highlightIndex]) {
        this.onOptionSelect(this.options[this.highlightIndex]);
      }
    },

    onOptionSelect(option) {
      const newValue = option;
      if (this.value !== newValue) {
        this.$emit("updated", newValue);
        this.$emit("input:update", newValue);
        this.$emit("update:value", newValue);
        this.$emit("changeValue", newValue);
      }
      this.isOpened = false;
      this.$refs.input.focus();
    },

    onFocus() {
      this.isFocused = true;
      this.$emit("focus");
    },

    onBlur() {
      if (this.isOpened) {
        this.isOpened = false;
      }
      this.isFocused = false;
      this.$emit("blur");
    },

    onClickOutside() {
      this.isOpened = false;
      this.isFocused = false;
      this.$emit("blur");
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  position: absolute;
  top: -4px;
  left: 0;
  transform: translatey(-100%);
  user-select: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #858585;
}

.dropdown {
  position: relative;
  display: inline-block;
  user-select: none;

  $block: &;

  &__required {
    position: absolute;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #f03738;
  }

  &._opened {
    .dropdown__arrow {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &._large {
    .dropdown__field {
      height: 52px;
    }
    .dropdown__input {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      color: #f18e35;
    }
  }

  &._disabled {
    .dropdown__field {
      opacity: 0.5;
      pointer-events: none;

      &:hover {
        .dropdown__input {
          color: #858585;
        }
      }
    }

    .dropdown__input {
      text-align: left;
      color: #858585;
    }

    .dropdown__arrow {
      color: orange;
    }
  }

  &._label {
    .dropdown__field {
      margin-top: 18px;
    }
  }

  &.dropdown-stretch {
    display: block;

    .dropdown__field {
      display: inline-flex;
      width: auto;
    }

    .dropdown__menu {
      width: 100%;
    }
  }

  &__field {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;

    padding: 20px 12px;
    width: 100%;
    min-width: 146px;
    height: 40px;
    border: 2px solid #f18e35;
    border-radius: 8px;

    background-color: #ffffff;

    @include mobile {
      width: 94px;
      height: 100%;
      max-height: 40px;
      min-width: auto;
      padding: 8px 12px;
    }

    //&:hover {
    //  .dropdown__input {
    //    color: orange;
    //  }
    //
    //  .dropdown__arrow {
    //    svg {
    //      fill: orange;
    //    }
    //  }
    //}
  }

  &__input {
    display: inline-block;
    width: 100%;
    transition: color 0.3s ease;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #f18e35;
  }

  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;

    color: orange;
    transition: transform 0.2s ease;
    cursor: pointer;
    overflow: hidden;
  }

  &__menu {
    position: absolute;
    width: 100%;
    top: 52px;
    z-index: 19;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 16px 0 #00000014;

    &.menu-enter-active,
    &.menu-leave-active {
      opacity: 1;
      transition: all 0.3s;
    }

    &.menu-enter,
    &.menu-leave-to {
      opacity: 0;
      transition: all 0.3s ease;
    }
  }

  &__scrollbox {
    width: 100%;
  }

  &__button {
    margin: 9px 12px;
  }

  &__option {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 14px 12px;
    transition: color 0.3s ease;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #222222;

    @include mobile {
      padding: 8px 12px;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }

    &._highlighted {
      background-color: #ebebeb;
    }

    &._disabled {
      color: rgba(0, 0, 0, 0.4);
      cursor: not-allowed;
    }

    svg {
      cursor: pointer;
    }
  }

  &__native {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;

    &._visible {
      pointer-events: auto;
      z-index: 2;
    }

    &.required {
      z-index: 2;
      display: block;
      pointer-events: auto;
    }
  }
}
</style>
<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css" />
