<template>
  <VButton
    class="wrapper"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
    nav
    :active="isActive"
  >
    <template #leftAddon>
      <IconPerson
        v-if="icon === 'settings' && role === 'CLIENT'"
        :color="color"
      />
      <IconPerson v-if="icon === 'clients'" :color="color" />
      <IconPerson v-if="icon === 'employees'" :color="color" />
      <IconSettings
        v-if="icon === 'account' && role !== 'CLIENT'"
        :color="color"
      />
      <IconReview v-if="icon === 'reviews'" :color="color" />
      <IconFav v-if="icon === 'favorites'" :color="color" />
      <IconBooking v-if="icon === 'bookings'" :color="color" />
      <IconBooking v-if="icon === 'business-booking'" :color="color" />
      <IconHouse v-if="icon === 'administrators'" :color="color" />
      <IconHouse v-if="icon === 'catering'" :color="color" />
      <IconStatistics v-if="icon === 'statistics'" :color="color" />
      <IconSettings
        v-if="icon === 'settings' && role !== 'CLIENT'"
        :color="color"
      />
      <IconDocuments v-if="icon === 'documents'" :color="color" />
      <IconDocuments v-if="icon === 'counterparties'" :color="color" />
      <IconTariffs v-if="icon === 'tariffs'" :color="color" />
      <IconPackages v-if="icon === 'packages'" :color="color" />
      <IconTariffs v-if="icon === 'payments'" :color="color" />
      <IconWidgetNav v-if="icon === 'widgets'" :color="color" />
    </template>
    <slot></slot>
  </VButton>
</template>

<script>
import VButton from "@/components/ui/VButton";
import IconBooking from "@/components/icons/IconBooking";
import IconFav from "@/components/icons/IconFav";
import IconReview from "@/components/icons/IconReview";
import IconPerson from "@/components/icons/IconPerson";
import IconHouse from "@/components/icons/IconHouse";
import IconStatistics from "@/components/icons/IconStatistics";
import IconSettings from "@/components/icons/IconSettings";
import IconDocuments from "@/components/icons/IconDocuments";
import IconTariffs from "@/components/icons/IconTariffs.vue";
import IconPackages from "@/components/icons/IconTariffs.vue";
import IconWidgetNav from "@/components/icons/IconWidgetNav.vue";

export default {
  name: "NavButton",
  components: {
    IconWidgetNav,
    IconPackages,
    IconTariffs,
    IconSettings,
    IconStatistics,
    IconHouse,
    VButton,
    IconPerson,
    IconReview,
    IconFav,
    IconBooking,
    IconDocuments,
  },
  data() {
    return {
      isHovered: false,
      currentPath: "default",
    };
  },
  computed: {
    color() {
      return this.isHovered || this.$route.path.includes(this.currentPath)
        ? "#fff"
        : "#F18E35";
    },
    isActive() {
      return this.$route.path.includes(this.currentPath);
    },
    role() {
      return this.$store.getters.role;
    },
  },
  props: {
    icon: {
      type: String,
      default: "",
    },
    path: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (!this.path) {
      this.currentPath = this.icon;
    } else {
      this.currentPath = this.path;
    }
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  background: #f6f6f6;
  color: #222222;

  :deep(.v-button__addon.is-left) {
    margin-right: 10px;
  }
}
</style>
