<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.64773 12.1114V13.5514H12.1705V15.6857H6.64773V18H2.48011V15.6857H0V13.5514H2.48011V0H10.2273C12.6648 0 14.5653 0.514285 15.929 1.54286C17.3097 2.57143 18 4.08 18 6.06857C18 8.04 17.3097 9.54 15.929 10.5686C14.5653 11.5971 12.6648 12.1114 10.2273 12.1114H6.64773ZM13.8068 6.09429C13.8068 5.22 13.4915 4.55143 12.8608 4.08857C12.2472 3.62572 11.3608 3.39429 10.2017 3.39429H6.64773V8.84571H10.2017C11.3608 8.84571 12.2472 8.61429 12.8608 8.15143C13.4915 7.67143 13.8068 6.98571 13.8068 6.09429Z"
      fill="#F18E35"
    />
  </svg>
</template>

<script>
export default {
  name: "IconRubble",
};
</script>

<style scoped></style>
