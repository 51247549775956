<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Person">
      <path
        id="Vector"
        d="M16.0007 16.0002C14.534 16.0002 13.2784 15.4779 12.234 14.4335C11.1895 13.3891 10.6673 12.1335 10.6673 10.6668C10.6673 9.20016 11.1895 7.94461 12.234 6.90016C13.2784 5.85572 14.534 5.3335 16.0007 5.3335C17.4673 5.3335 18.7229 5.85572 19.7673 6.90016C20.8118 7.94461 21.334 9.20016 21.334 10.6668C21.334 12.1335 20.8118 13.3891 19.7673 14.4335C18.7229 15.4779 17.4673 16.0002 16.0007 16.0002ZM24.0007 26.6668H8.00065C7.26732 26.6668 6.63976 26.4059 6.11798 25.8842C5.59532 25.3615 5.33398 24.7335 5.33398 24.0002V22.9335C5.33398 22.1779 5.52865 21.4833 5.91798 20.8495C6.30643 20.2166 6.82287 19.7335 7.46732 19.4002C8.8451 18.7113 10.2451 18.1944 11.6673 17.8495C13.0895 17.5055 14.534 17.3335 16.0007 17.3335C17.4673 17.3335 18.9118 17.5055 20.334 17.8495C21.7562 18.1944 23.1562 18.7113 24.534 19.4002C25.1784 19.7335 25.6949 20.2166 26.0833 20.8495C26.4727 21.4833 26.6673 22.1779 26.6673 22.9335V24.0002C26.6673 24.7335 26.4064 25.3615 25.8846 25.8842C25.362 26.4059 24.734 26.6668 24.0007 26.6668ZM8.00065 24.0002H24.0007V22.9335C24.0007 22.6891 23.9398 22.4668 23.818 22.2668C23.6953 22.0668 23.534 21.9113 23.334 21.8002C22.134 21.2002 20.9229 20.7499 19.7006 20.4495C18.4784 20.1499 17.2451 20.0002 16.0007 20.0002C14.7562 20.0002 13.5229 20.1499 12.3007 20.4495C11.0784 20.7499 9.86732 21.2002 8.66732 21.8002C8.46732 21.9113 8.30643 22.0668 8.18465 22.2668C8.06198 22.4668 8.00065 22.6891 8.00065 22.9335V24.0002ZM16.0007 13.3335C16.734 13.3335 17.362 13.0722 17.8847 12.5495C18.4064 12.0277 18.6673 11.4002 18.6673 10.6668C18.6673 9.9335 18.4064 9.30594 17.8847 8.78416C17.362 8.2615 16.734 8.00016 16.0007 8.00016C15.2673 8.00016 14.6398 8.2615 14.118 8.78416C13.5953 9.30594 13.334 9.9335 13.334 10.6668C13.334 11.4002 13.5953 12.0277 14.118 12.5495C14.6398 13.0722 15.2673 13.3335 16.0007 13.3335Z"
        fill="#F18E35"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconBookingPerson",
  props: {
    color: {
      type: String,
      default: "#F18E35",
    },
  },
};
</script>

<style scoped></style>
