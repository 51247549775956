<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 17V17.5H2H3.4H3.60711L3.75355 17.3536L12.3786 8.72855L12.7321 8.375L12.3786 8.02145L11.6786 7.32145L10.9786 6.62145L10.625 6.26789L10.2714 6.62145L1.64645 15.2464L1.5 15.3929V15.6V17ZM16.2979 6.21998L12.7592 2.7229L13.8036 1.67855C14.09 1.39214 14.4324 1.25 14.863 1.25C15.2927 1.25 15.6349 1.39202 15.9214 1.67855L17.3214 3.07855C17.6147 3.37185 17.7625 3.71356 17.7752 4.12837C17.7877 4.53168 17.6573 4.86055 17.3714 5.14645L16.2979 6.21998ZM10.6 4.85711L14.1429 8.4L4.04289 18.5H0.5V14.9571L10.6 4.85711Z"
      :fill="color"
      :stroke="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconPen",
  props: {
    color: {
      type: String,
      default: "#F18E35",
    },
  },
};
</script>

<style scoped></style>
