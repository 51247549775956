<script setup>
import { ref, defineProps, defineEmits, watch, nextTick } from "vue";
import VInput from "@/components/ui/VInput.vue";

const input = ref(null);

const isEdit = ref(false);

const emit = defineEmits(["update"]);

const onBlurHandler = () => {
  isEdit.value = false;
  emit("update", input.value.cleanValue);
};

defineProps({
  value: Number,
});

watch(isEdit, (val) => {
  if (val) {
    nextTick(() => input.value.onFocus());
  }
});
</script>

<template>
  <VInput
    v-if="isEdit"
    class="input"
    type="number"
    ref="input"
    :value="value"
    @blur="onBlurHandler"
    @keydown.enter="onBlurHandler"
  />
  <span v-else @click="isEdit = true" @blur="onBlurHandler"> {{ value }}</span>
</template>

<style lang="scss" scoped>
span {
  color: #222222;
  font-size: 36px;
  font-weight: 400;
  line-height: 56px;
}

.input {
  width: 120px;
  height: 56px;
  border: none;
  caret-color: #f18e35;
  :deep(.v-input__native) {
    color: #f18e35;
    font-size: 36px;
    font-weight: 400;
    line-height: 56px;
    text-align: center;
    padding: 0;
  }
}
</style>
