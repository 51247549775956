<template>
  <label :for="forId" :class="classes">
    <template v-if="type === 'add'">
      <IconPlus color="#858585" />
    </template>
    <template v-if="type === 'addWithText'">
      <div class="content _row">
        <IconPlus color="#858585" />
        <span>Добавить фото</span>
      </div>
    </template>
    <template v-else-if="type === 'load'">
      <IconLoad color="#F18E35" />
    </template>
    <template v-else-if="type === 'error'">
      <div class="content">
        <IconLoadError />
        <span>Попробуйте снова</span>
      </div>
    </template>
    <template v-else-if="type === 'invalid'">
      <div class="content">
        <IconInvalidType />
        <span>Неподходящий формат</span>
      </div>
    </template>
    <template v-else-if="type === 'image'">
      <VImage :src="image" />
    </template>
  </label>
</template>

<script>
import IconPlus from "@/components/icons/IconPlus";
import IconLoadError from "@/components/icons/IconLoadError";
import IconInvalidType from "@/components/icons/IconInvalidType";
import IconLoad from "@/components/icons/IconLoad";
import VImage from "@/components/ui/VImage";

export default {
  name: "ImageLoadLabel",
  components: {
    IconLoad,
    IconPlus,
    IconLoadError,
    IconInvalidType,
    VImage,
  },

  props: {
    type: {
      type: String,
      default: "add",
    },
    forId: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
  },
  computed: {
    classes() {
      return { "load-wrapper": true, _load: this.type === "load" };
    },
  },
};
</script>

<style lang="scss" scoped>
.load-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  border: 1px dashed #d6d6d6;
  border-radius: 8px;
  cursor: pointer;

  &._load {
    cursor: not-allowed;
    pointer-events: none;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &._row {
    flex-direction: row;

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }

    svg {
      margin-bottom: 0;
      margin-right: 8px;
    }
  }

  span {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #858585;
  }

  svg {
    margin-bottom: 6px;
  }
}
</style>
