<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.289 13.5041C17.7165 14.8584 16.821 16.0518 15.6808 16.98C14.5406 17.9082 13.1904 18.5429 11.7483 18.8286C10.3063 19.1143 8.81619 19.0423 7.40837 18.6189C6.00055 18.1954 4.71785 17.4335 3.67243 16.3997C2.627 15.3659 1.85068 14.0917 1.41133 12.6884C0.971974 11.2852 0.88297 9.79563 1.1521 8.35002C1.42123 6.90442 2.04029 5.54676 2.95517 4.39576C3.87004 3.24475 5.05288 2.33544 6.40026 1.74731"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.9998 10.0021C18.9998 8.81992 18.767 7.64933 18.3148 6.55714C17.8625 5.46496 17.1995 4.47257 16.3638 3.63665C15.5281 2.80073 14.536 2.13764 13.4441 1.68524C12.3522 1.23285 11.1819 1 10 1V10.0021H18.9998Z"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconStatistics",

  props: {
    color: {
      type: String,
      default: "#F18E35",
    },
  },
};
</script>

<style scoped></style>
