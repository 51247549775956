<template>
  <div :class="['BooleanFilter', classes]">
    <label :class="bodyClasses">
      <input
        :id="label"
        type="checkbox"
        :checked="isChecked"
        @change="handleChange"
      />
      <span class="label" :for="label">
        <span>
          <slot>
            {{ label }}
          </slot>
        </span>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "BooleanFilter",

  emits: ["change"],

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "",
    },

    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isChecked() {
      return this.value;
    },

    classes() {
      return {
        _checked: this.isChecked,
        _disabled: this.disabled,
      };
    },

    bodyClasses() {
      return ["body"];
    },
  },

  methods: {
    handleChange({ target }) {
      this.$emit("change", target.checked ? 1 : 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.BooleanFilter {
  position: relative;
  display: block;
  width: 100%;
  user-select: none;

  @include hover {
    &:not(._checked):hover {
      .body {
        background-color: #f5a94d;
      }
      .label {
        color: #fff;
      }
    }
  }

  &._checked {
    .body {
      font-weight: 600;
      background-color: #f18e35;
      color: #fff;
    }
  }

  &._disabled {
    cursor: not-allowed;

    .body {
      background-color: rgba(black, 0.2);
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 14px;
  height: 28px;
  border-radius: 30px;
  background-color: #ebebeb;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #2a2a30;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
  cursor: pointer;
}

.label {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
}
</style>
