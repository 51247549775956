<template>
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.99997 15.75H11C11.2125 15.75 11.3906 15.6781 11.5344 15.5343C11.6781 15.3904 11.75 15.2122 11.75 14.9997C11.75 14.7871 11.6781 14.609 11.5344 14.4654C11.3906 14.3218 11.2125 14.25 11 14.25H4.99997C4.78748 14.25 4.60936 14.3219 4.46563 14.4657C4.32188 14.6095 4.25 14.7877 4.25 15.0003C4.25 15.2129 4.32188 15.391 4.46563 15.5346C4.60936 15.6782 4.78748 15.75 4.99997 15.75ZM4.99997 11.75H11C11.2125 11.75 11.3906 11.6781 11.5344 11.5343C11.6781 11.3904 11.75 11.2122 11.75 10.9997C11.75 10.7871 11.6781 10.609 11.5344 10.4654C11.3906 10.3218 11.2125 10.25 11 10.25H4.99997C4.78748 10.25 4.60936 10.3219 4.46563 10.4657C4.32188 10.6095 4.25 10.7877 4.25 11.0003C4.25 11.2129 4.32188 11.391 4.46563 11.5346C4.60936 11.6782 4.78748 11.75 4.99997 11.75ZM2.3077 19.5C1.80257 19.5 1.375 19.325 1.025 18.975C0.675 18.625 0.5 18.1974 0.5 17.6923V2.3077C0.5 1.80257 0.675 1.375 1.025 1.025C1.375 0.675 1.80257 0.5 2.3077 0.5H9.50193C9.74579 0.5 9.97823 0.546801 10.1993 0.640401C10.4202 0.733984 10.6128 0.862826 10.7769 1.02693L14.973 5.22303C15.1371 5.38714 15.266 5.5797 15.3595 5.8007C15.4531 6.02172 15.5 6.25416 15.5 6.49803V17.6923C15.5 18.1974 15.325 18.625 14.975 18.975C14.625 19.325 14.1974 19.5 13.6922 19.5H2.3077ZM9.5 5.59995V1.99998H2.3077C2.23077 1.99998 2.16024 2.03203 2.09612 2.09613C2.03202 2.16024 1.99997 2.23077 1.99997 2.3077V17.6923C1.99997 17.7692 2.03202 17.8397 2.09612 17.9038C2.16024 17.9679 2.23077 18 2.3077 18H13.6922C13.7692 18 13.8397 17.9679 13.9038 17.9038C13.9679 17.8397 14 17.7692 14 17.6923V6.49995H10.3998C10.1448 6.49995 9.93111 6.4137 9.75868 6.2412C9.58623 6.0687 9.5 5.85495 9.5 5.59995Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconTariffs",

  props: {
    color: {
      type: String,
      default: "#F18E35",
    },
  },
};
</script>

<style scoped></style>
