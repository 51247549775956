<template>
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7644 5.57828C12.0785 5.90171 12.0785 6.4261 11.7644 6.74953C11.4503 7.07296 10.941 7.07296 10.6269 6.74953L5.44494 1.41383C5.13083 1.09039 5.13083 0.566006 5.44494 0.242574C5.75906 -0.0808583 6.26834 -0.0808587 6.58245 0.242573L11.7644 5.57828Z"
      fill="#222222"
    />
    <path
      d="M1.37309 6.75743C1.05898 7.08086 0.549697 7.08086 0.235584 6.75742C-0.0785293 6.43399 -0.0785293 5.9096 0.235584 5.58617L5.41755 0.250469C5.73166 -0.0729638 6.24094 -0.0729633 6.55506 0.250469C6.86917 0.573902 6.86917 1.09829 6.55506 1.42172L1.37309 6.75743Z"
      fill="#222222"
    />
  </svg>
</template>

<script>
export default {
  name: "IconAccordionArrow",
};
</script>

<style scoped></style>
