<template>
  <div :class="['container', data.status === 'Send' && '_unread']">
    <span class="date">{{ getDate(data.createTime) }}</span>
    <span class="notice" v-html="data.text" />
  </div>
</template>

<script>
export default {
  name: "Notice",
  props: {
    data: {
      type: String,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close", this.id);
    },
    getDate(val) {
      let date = new Date(val);
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return date.toLocaleString("ru", options).replace(",", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 8px 16px 8px;
  background-color: #ffffff;
  border-bottom: 1px solid #ebebeb;

  &._unread {
    background-color: #fef6e8;
  }

  &:last-child {
    border-bottom: none;
  }
}

.title {
  color: #f18e35;
}

.date {
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.notice {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #222222;

  & :deep(.bold) {
    font-weight: 400;
    color: #222222;
  }

  & :deep(.color) {
    font-weight: 600;
    color: #f18e35;
  }
}
</style>
