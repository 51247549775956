<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.17187 12.1548C2.17187 11.5981 2.62345 11.1468 3.18049 11.1468L21.1238 11.1468C21.6809 11.1468 22.1325 11.5981 22.1325 12.1548C22.1325 12.7114 21.6809 13.1627 21.1238 13.1627L3.18049 13.1627C2.62345 13.1627 2.17187 12.7114 2.17187 12.1548Z"
      fill="#222222"
    />
    <path
      d="M12.4669 20.8474C12.0731 20.4537 12.0731 19.8156 12.4669 19.4219L20.4501 11.4442C20.8439 11.0506 21.4826 11.0506 21.8765 11.4442C22.2703 11.8378 22.2703 12.476 21.8765 12.8697L13.8933 20.8474C13.4995 21.241 12.8608 21.241 12.4669 20.8474Z"
      fill="#222222"
    />
    <path
      d="M12.4526 3.4378C12.8465 3.04417 13.4851 3.04417 13.879 3.4378L21.8621 11.4155C22.256 11.8091 22.256 12.4473 21.8621 12.8409C21.4682 13.2346 20.8296 13.2346 20.4357 12.8409L12.4526 4.86323C12.0587 4.46961 12.0587 3.83142 12.4526 3.4378Z"
      fill="#222222"
    />
  </svg>
</template>

<script>
export default {
  name: "IconNotificationArrow",

  props: {
    color: {
      type: String,
      default: "#222222",
    },
  },
};
</script>

<style scoped></style>
