import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { globalDirectives } from "@/assets/js/directives";
import VueKonva from "vue-konva";
import VueTheMask from "vue-the-mask";

createApp(App)
  .use(store)
  .use(router)
  .use(globalDirectives)
  .use(VueKonva)
  .use(VueTheMask)
  .mount("#app");

Date.prototype.daysInMonth = function () {
  return 33 - new Date(this.getFullYear(), this.getMonth(), 33).getDate();
};
