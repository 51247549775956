<template>
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 8.075L10 1.25L19 8.075V18.8C19 19.3172 18.7893 19.8132 18.4142 20.1789C18.0391 20.5446 17.5304 20.75 17 20.75H3C2.46957 20.75 1.96086 20.5446 1.58579 20.1789C1.21071 19.8132 1 19.3172 1 18.8V8.075Z"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 20.75V11H13V20.75"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconRestaurants",

  props: {
    color: {
      type: String,
      default: "#F18E35",
    },
  },
};
</script>

<style scoped></style>
