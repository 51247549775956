<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.625 15.5C12.8333 15.5 11.0869 15.1006 9.38583 14.3017C7.68417 13.5033 6.17722 12.4478 4.865 11.135C3.55222 9.82278 2.49667 8.31583 1.69833 6.61417C0.899445 4.91306 0.5 3.16667 0.5 1.375C0.5 1.125 0.583333 0.916667 0.75 0.75C0.916667 0.583333 1.125 0.5 1.375 0.5H4.75C4.94444 0.5 5.11806 0.5625 5.27083 0.6875C5.42361 0.8125 5.51389 0.972222 5.54167 1.16667L6.08333 4.08333C6.11111 4.27778 6.10778 4.45472 6.07333 4.61417C6.03833 4.77417 5.95833 4.91667 5.83333 5.04167L3.8125 7.08333C4.39583 8.08333 5.12861 9.02083 6.01083 9.89583C6.8925 10.7708 7.86111 11.5278 8.91667 12.1667L10.875 10.2083C11 10.0833 11.1633 9.98944 11.365 9.92667C11.5661 9.86444 11.7639 9.84722 11.9583 9.875L14.8333 10.4583C15.0278 10.5 15.1875 10.5936 15.3125 10.7392C15.4375 10.8853 15.5 11.0556 15.5 11.25V14.625C15.5 14.875 15.4167 15.0833 15.25 15.25C15.0833 15.4167 14.875 15.5 14.625 15.5ZM3.02083 5.5L4.39583 4.125L4.04167 2.16667H2.1875C2.25694 2.73611 2.35417 3.29861 2.47917 3.85417C2.60417 4.40972 2.78472 4.95833 3.02083 5.5ZM13.8333 13.7917V11.9583L11.875 11.5625L10.4792 12.9583C11.0208 13.1944 11.5731 13.3819 12.1358 13.5208C12.6981 13.6597 13.2639 13.75 13.8333 13.7917Z"
      fill="#F18E35"
    />
  </svg>
</template>

<script>
export default {
  name: "IconPhone",
};
</script>

<style scoped></style>
