<template>
  <div class="inner">
    <h2>Учетная запись забокирована</h2>
    <IconCheckAwaiting class="icon" />
    <div class="buttons">
      <VButton class="button" @click="$store.dispatch('closeModal')">
        Вернуться назад
      </VButton>
    </div>
    <IconPopupCross
      v-if="screenWidth !== 'mobile' && !isEvisoBusinessAuth"
      class="modal-cross"
      @click="$store.dispatch('closeModal')"
    />
  </div>
  <IconBack
    v-if="screenWidth !== 'mobile' && isEvisoBusinessAuth"
    class="arrow"
    @click="onArrowClickHandler"
  />
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import IconPopupCross from "@/components/icons/IconPopupCross.vue";
import VButton from "@/components/ui/VButton.vue";
import IconCheckAwaiting from "@/components/icons/IconCheckAwaiting.vue";
import { useRoute } from "vue-router";
import IconBack from "@/components/icons/IconBack.vue";
import AuthLoginModal from "@/components/modals/auth/AuthLoginModal.vue";

const store = useStore();
const route = useRoute();

const screenWidth = computed(() => {
  return store.getters.screenType;
});

const isEvisoBusinessAuth = computed(() => {
  return route.name === "auth-biz" || route.name === "auth-eviso";
});

const onArrowClickHandler = () => {
  store.dispatch("openModal", {
    value: {
      component: AuthLoginModal,
      props: null,
    },
  });
};
</script>

<style lang="scss" scoped>
.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 448px;
  margin: auto;
  padding: 32px 56px 40px 56px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  @media screen and (max-width: 841px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px 0 #0000000d;
    border-radius: 20px 20px 0 0;
    padding: 32px 24px 24px 24px;
  }
}

h2 {
  margin-bottom: 24px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #f0811e;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
}

.button {
  width: 100%;
}

.modal-cross {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200003;
  cursor: pointer;
}

.icon {
  margin: 0 auto;
}
</style>
