<template>
  <div
    class="wrapper"
    :class="{ _isSidebar: this.catId }"
    :data-id="id"
    @click.stop="onClickHandler"
    @mousedown.stop
    @mousemove.stop
    @mouseip.stop
    @dblclick.stop
  >
    <div class="description">
      <div class="main-info">
        <template v-if="item">
          <VImage
            v-if="logo"
            :src="`${url}files/cat/${item.id}/logo`"
            class="main-info-logo"
          />
          <VImage v-else :src="defaultLogo" class="main-info-default-logo" />
        </template>
        <span class="main-info-title">{{ item?.name }}</span>
        <span class="main-info-price">{{ item?.averageCheck }} ₽</span>
      </div>
      <div class="details">
        <div v-if="item?.address">
          <div class="svg-container">
            <IconGeoMarker />
          </div>
          <span>{{ item?.address }}</span>
        </div>
        <div>
          <div class="svg-container">
            <IconWatch />
          </div>
          <span v-if="isOpen()[1]" class="details-tablo-text">
            {{ isOpen()[1] }}
          </span>
          <span class="details-tablo"
            >{{ isOpen()[0] ? "Открыто" : "Закрыто" }}
          </span>
        </div>
        <div v-if="item?.phone">
          <div class="svg-container">
            <IconPhone />
          </div>
          <span>{{ item?.phone }}</span>
        </div>
        <div v-if="item?.site">
          <div class="svg-container">
            <IconWeb />
          </div>
          <a :href="formatLink(item.site)" target="_blank">
            {{ item?.site }}
          </a>
        </div>
      </div>
      <VButton outline block @click.stop="book" :disabled="isDisabled">
        Забронировать
      </VButton>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/ui/VButton.vue";
import IconGeoMarker from "@/components/icons/IconGeoMarker.vue";
import IconWatch from "@/components/icons/IconWatch.vue";
import IconPhone from "@/components/icons/IconPhone.vue";
import IconWeb from "@/components/icons/IconWeb.vue";
import { WEEKDAYS } from "@/assets/js/utils/consts";
import VImage from "@/components/ui/VImage.vue";
import Logo from "@/assets/images/logo.png";
import { prodCheck } from "@/assets/js/utils/prodCheck";
import { BASE_URL } from "@/assets/js/api/baseUrl";
import { API_WITHOUT_GUARDS } from "@/assets/js/api/apiWithoutGuards";
import { formatLink } from "@/assets/js/utils/helpers";

export default {
  name: "ObjectItem",

  props: {
    catId: String,
  },

  data() {
    return {
      defaultLogo: Logo,
      logo: "",
      url: "",
    };
  },
  components: { VImage, IconPhone, IconWatch, IconGeoMarker, VButton, IconWeb },

  methods: {
    formatLink,
    onClickHandler() {
      this.$router.push({
        path: `${this.item.id}`,
      });
    },

    book() {
      this.$router.push(`/booking/${this.item.id}`);
    },

    isOpen() {
      if (this.item) {
        const date = new Date();
        const currentTime = `${
          date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
        }:${
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
        }`;
        const currentWeekday = WEEKDAYS[date.getDay()];

        const targetWeekday = this.item.workingTime.find((el) =>
          el.weekDays.includes(currentWeekday)
        );

        if (!targetWeekday) {
          return [false];
        }

        return this.isPlaceOpen(targetWeekday?.time, currentTime);
      } else {
        return [false];
      }
    },

    formatTime(timeString) {
      const hour = timeString.slice(0, 2);
      const minute = timeString.slice(2);
      return `${hour}:${minute}`;
    },

    isPlaceOpen(workingHours, time) {
      if (!workingHours) {
        return [];
      }

      const openingTime = this.formatTime(workingHours[0]);
      const closingTime = this.formatTime(workingHours[1]);

      const currentTime = new Date(`2000-01-01T${time}`);
      const opening = new Date(`2000-01-01T${openingTime}`);
      const closing = new Date(`2000-01-01T${closingTime}`);

      if (+workingHours[1] < 600) {
        closing.setDate(2);
      }

      return [
        currentTime >= opening && currentTime <= closing,
        `${openingTime} - ${closingTime}`,
      ];
    },
  },

  computed: {
    isDisabled() {
      if (this.item?.scheme && this.item?.available) {
        if (!this.isAuth || this.role === "CLIENT") {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    role() {
      return this.$store.getters.role;
    },
    isAuth() {
      return this.$store.state.general.auth.isAuth;
    },
    cateringID() {
      if (this.catId) {
        return this.catId;
      } else {
        return this.$store.state.clientRestaurants.hoveredCatering;
      }
    },
    item() {
      return this.$store.state.clientRestaurants.items.find(
        (el) => el.id === this.cateringID
      );
    },
  },

  watch: {
    async cateringID() {
      this.url = prodCheck() ? window.location.origin + "/api/v1/" : BASE_URL;

      try {
        const images = await API_WITHOUT_GUARDS.catering.getLogoByEviso(
          this.cateringID
        );
        this.logo = images.data;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 250px;
  cursor: pointer;

  &._isSidebar {
    width: 100%;
  }
}

.description {
  padding: 16px;
}

.details {
  margin-bottom: 24px;
  &-tablo {
    color: #f18e35;
  }
}

.main-info {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #222222;

  &-price {
    margin-left: auto;
  }

  &-title {
    max-width: 130px;
    overflow-wrap: break-word;
  }

  &-default-logo {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 50%;
    background-color: #ebebeb;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    filter: grayscale(1);
  }

  &-logo {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 50%;
  }
}

.details {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #222222;

  & > div {
    display: flex;
    align-items: center;
  }

  & > div:not(:last-child) {
    margin-bottom: 8px;
  }

  svg {
    margin-right: 6px;
  }

  .details-tablo-text {
    margin-right: 6px;
  }
}
</style>
