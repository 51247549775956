<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "StatusIcon",
  props: {
    color: {
      type: String,
      default: "green",
    },
  },

  computed: {
    classes() {
      return [
        `statusIcon`,
        {
          red: this.color === "red",
          green: this.color === "green",
          grey: this.color === "grey",
          yellow: this.color === "yellow",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.statusIcon {
  display: inline-block;
  padding: 2px 8px;
  height: 24px;
  width: 128px;
  border-radius: 6px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  user-select: none;

  @include mobile {
    width: 102px;
  }
}

.green {
  background: #ebf9eb;
  border: 1px solid #9de09d;
  color: #3cc13b;
}

.red {
  background: #fdebeb;
  border: 1px solid #f79b9b;
  color: #f03738;
}

.yellow {
  background: #fef6e8;
  border: 1px solid #f9d48d;
  color: #fabb44;
}

.orange {
  background: #fef6e8;
  border: 1px solid #f18e35;
  color: #f0811e;
}

.grey {
  background: #f6f6f6;
  border: 1px solid #ebebeb;
  color: #858585;
}
</style>
