<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99984 13.1665C9.23595 13.1665 9.434 13.0865 9.594 12.9265C9.75345 12.7671 9.83317 12.5693 9.83317 12.3332C9.83317 12.0971 9.75345 11.899 9.594 11.739C9.434 11.5796 9.23595 11.4998 8.99984 11.4998C8.76373 11.4998 8.56595 11.5796 8.4065 11.739C8.2465 11.899 8.1665 12.0971 8.1665 12.3332C8.1665 12.5693 8.2465 12.7671 8.4065 12.9265C8.56595 13.0865 8.76373 13.1665 8.99984 13.1665ZM8.1665 8.99984C8.1665 9.46008 8.5396 9.83317 8.99984 9.83317C9.46008 9.83317 9.83317 9.46007 9.83317 8.99984V5.6665C9.83317 5.20627 9.46008 4.83317 8.99984 4.83317C8.5396 4.83317 8.1665 5.20627 8.1665 5.6665V8.99984ZM8.99984 17.3332C7.84706 17.3332 6.76373 17.1143 5.74984 16.6765C4.73595 16.2393 3.854 15.6457 3.104 14.8957C2.354 14.1457 1.76039 13.2637 1.32317 12.2498C0.885393 11.2359 0.666504 10.1526 0.666504 8.99984C0.666504 7.84706 0.885393 6.76373 1.32317 5.74984C1.76039 4.73595 2.354 3.854 3.104 3.104C3.854 2.354 4.73595 1.76012 5.74984 1.32234C6.76373 0.885115 7.84706 0.666504 8.99984 0.666504C10.1526 0.666504 11.2359 0.885115 12.2498 1.32234C13.2637 1.76012 14.1457 2.354 14.8957 3.104C15.6457 3.854 16.2393 4.73595 16.6765 5.74984C17.1143 6.76373 17.3332 7.84706 17.3332 8.99984C17.3332 10.1526 17.1143 11.2359 16.6765 12.2498C16.2393 13.2637 15.6457 14.1457 14.8957 14.8957C14.1457 15.6457 13.2637 16.2393 12.2498 16.6765C11.2359 17.1143 10.1526 17.3332 8.99984 17.3332ZM8.99984 15.6665C10.8609 15.6665 12.4373 15.0207 13.729 13.729C15.0207 12.4373 15.6665 10.8609 15.6665 8.99984C15.6665 7.13873 15.0207 5.56234 13.729 4.27067C12.4373 2.979 10.8609 2.33317 8.99984 2.33317C7.13873 2.33317 5.56234 2.979 4.27067 4.27067C2.979 5.56234 2.33317 7.13873 2.33317 8.99984C2.33317 10.8609 2.979 12.4373 4.27067 13.729C5.56234 15.0207 7.13873 15.6665 8.99984 15.6665Z"
      fill="#E3312D"
    />
  </svg>
</template>

<script>
export default {
  name: "IconLoadError",
};
</script>
