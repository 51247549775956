<template>
  <div class="inner">
    <h2>Новый пароль</h2>
    <form
      class="form"
      @submit.prevent="handleSubmit"
      @keydown.enter="handleSubmit"
    >
      <VInput
        v-model="password"
        class="input"
        type="password"
        placeholder="Пароль"
        @enter="handleSubmit"
        @input="error = ''"
      />

      <span v-if="error" class="error"> {{ error }} </span>
      <div class="buttons">
        <AsyncButton class="button" type="submit" :is-pending="isPending">
          Изменить
        </AsyncButton>
      </div>
    </form>
    <IconPopupCross
      v-if="screenWidth !== 'mobile' && !isEvisoBusinessAuth"
      class="modal-cross"
      @click="$store.dispatch('closeModal')"
    />
  </div>
  <IconBack
    v-if="screenWidth !== 'mobile' && isEvisoBusinessAuth"
    class="arrow"
    @click="onArrowClickHandler"
  />
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import IconBack from "@/components/icons/IconBack.vue";
import AuthLoginModal from "@/components/modals/auth/AuthLoginModal.vue";

const store = useStore();
const route = useRoute();

const screenWidth = computed(() => {
  return store.getters.screenType;
});

const isEvisoBusinessAuth = computed(() => {
  return route.name === "auth-biz" || route.name === "auth-eviso";
});

const onArrowClickHandler = () => {
  store.dispatch("openModal", {
    value: {
      component: AuthLoginModal,
      props: null,
    },
  });
};
</script>

<script>
import AsyncButton from "@/components/ui/AsyncButton.vue";
import VInput from "@/components/ui/VInput.vue";
import { API_WITHOUT_GUARDS } from "@/assets/js/api/apiWithoutGuards";
import IconPopupCross from "@/components/icons/IconPopupCross.vue";
import AuthRestorationSuccessModal from "@/components/modals/auth/AuthRestorationSuccessModal.vue";
import { API } from "@/assets/js/api/api";

export default {
  name: "AuthRestorationPassword",

  components: {
    IconPopupCross,
    VInput,
    AsyncButton,
  },

  props: {
    contacts: {
      type: String,
    },
    roleType: {
      type: String,
    },
  },

  data() {
    return {
      password: "",
      error: "",
      isPending: false,
    };
  },

  methods: {
    async handleSubmit() {
      this.isPending = true;

      try {
        if (this.roleType === "client") {
          await API_WITHOUT_GUARDS.client.setPassword({
            password: this.password,
            phone: this.contacts,
          });
        } else if (this.roleType === "eviso") {
          await API.eviso.auth.setPassword({
            password: this.password,
            userId: this.$store.state.general.jwtAT.preferred_username,
          });
        } else {
          await API.business.setPassword({
            password: this.password,
            userId: this.$store.state.general.jwtAT.preferred_username,
          });
        }

        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        this.$store.commit("setjwtAT", {});

        this.$store.dispatch("openModal", {
          value: {
            component: AuthRestorationSuccessModal,
          },
        });
      } catch (e) {
        this.error = "Новый пароль совпадает с предыдущим";
        console.log(e);
      } finally {
        this.isPending = false;
      }
    },
  },

  beforeUnmount() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    this.$store.commit("setjwtAT", {});
  },
};
</script>

<style lang="scss" scoped>
.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 448px;
  margin: auto;
  padding: 32px 56px 40px 56px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  @media screen and (max-width: 841px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px 0 #0000000d;
    border-radius: 20px 20px 0 0;
    padding: 32px 24px 24px 24px;
  }
}

h2 {
  margin-bottom: 24px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #f0811e;
}

p {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #222222;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
}

.button {
  width: 100%;
}

.modal-cross {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.icon {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.input {
  height: 40px;
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
}

.error {
  position: relative;
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #f03738;
}

.arrow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200003;
  cursor: pointer;
}
</style>
