<template>
  <VClientFilter
    :items="filteredItemsMain"
    :initItems="initItems"
    @update="updateHandler"
    @filter="filter"
    @update-kitchen="updateKitchenHandler"
    @reset="resetHandler"
    :filters="filters"
    v-if="isShown"
  />
  <div class="step-info">
    <BookingStepInfo
      v-if="filterVisitors"
      icon-type="IconBookingPerson"
      :text="filterVisitors"
      class="step-info-step"
      @click="openVisitorsModal"
      map
      filled
    />
    <BookingStepInfo
      v-else
      icon-type="IconBookingPerson"
      text="Кол-во гостей"
      class="step-info-step"
      @click="openVisitorsModal"
      map
    />
    <BookingStepInfo
      v-if="filterDate"
      icon-type="IconBookingCalendar"
      :text="filterDate.toLocaleDateString('ru')"
      class="step-info-step"
      @click="openDateModal"
      map
      filled
    />
    <BookingStepInfo
      v-else
      icon-type="IconBookingCalendar"
      text="Дата"
      class="step-info-step"
      @click="openDateModal"
      map
    />
    <BookingStepInfo
      v-if="filterDuration"
      icon-type="IconBookingDuration"
      :text="filterDuration / 60 + ' ч'"
      class="step-info-step"
      @click="openDurationModal"
      map
      filled
    />
    <BookingStepInfo
      v-else
      icon-type="IconBookingDuration"
      text="Длительность"
      class="step-info-step"
      @click="openDurationModal"
      map
    />
    <BookingStepInfo
      v-if="filterTime"
      icon-type="IconBookingTime"
      :text="`${filterTime.hours}:${
        filterTime.minutes === 0 ? '00' : filterTime.minutes
      }`"
      class="step-info-step"
      @click="openTimeModal"
      map
      filled
    />
    <BookingStepInfo
      v-else
      icon-type="IconBookingTime"
      text="Время"
      class="step-info-step"
      @click="openTimeModal"
      map
    />
    <div class="cross" v-if="isFilled" @click="resetFilter">
      <IconCross color="#858585" />
    </div>
    <VButton v-else large class="button" @click="openVisitorsModal">
      Найти столик
    </VButton>
  </div>
  <VMap :items="filteredItemsMain" />
</template>

<script>
import VMap from "@/components/pages/home/VMap.vue";
import { WEEKDAYS } from "@/assets/js/utils/consts";
import VClientFilter from "@/components/pages/home/VClientFilter.vue";
import ChangeRoleModal from "@/components/modals/ChangeRoleModal.vue";
import BookingStepInfo from "@/components/pages/client-booking/BookingStepInfo.vue";
import FilterDateModal from "@/components/modals/FilterDateModal.vue";
import FilterTimeModal from "@/components/modals/FilterTimeModal.vue";
import FilterVisitorsModal from "@/components/modals/FilterVisitorsModal.vue";
import FilterDurationModal from "@/components/modals/FilterDurationModal.vue";
import VButton from "@/components/ui/VButton.vue";
import IconCross from "@/components/icons/IconCross.vue";

export default {
  name: "MapPage",
  components: {
    IconCross,
    VButton,
    BookingStepInfo,
    VClientFilter,
    VMap,
  },

  methods: {
    openDurationModal() {
      this.$store.dispatch("openModal", {
        value: {
          component: FilterDurationModal,
          props: {},
        },
      });
    },
    openDateModal() {
      this.$store.dispatch("openModal", {
        value: {
          component: FilterDateModal,
          props: {},
        },
      });
    },
    openTimeModal() {
      this.$store.dispatch("openModal", {
        value: {
          component: FilterTimeModal,
          props: {},
        },
      });
    },
    openVisitorsModal() {
      this.$store.dispatch("openModal", {
        value: {
          component: FilterVisitorsModal,
          props: {},
        },
      });
    },
    async filter() {
      if (
        this.filterDate &&
        this.filterTime &&
        this.filterVisitors &&
        this.filterDuration
      ) {
        const date = new Date();
        date.setUTCDate(this.filterDate.getDate());
        date.setUTCHours(+this.filterTime.hours);
        date.setUTCMinutes(+this.filterTime.minutes);
        date.setUTCSeconds(0);

        await this.$store.dispatch("getClientRestaurantsDate", {
          value: {
            visitTime: date,
            visitors: this.filterVisitors,
            duration: this.filterDuration,
            city: "Екатеринбург",
            settlement: "",
          },
        });
      }
      this.items = this.filteredItems();
    },

    filteredItems() {
      let result = this.initItems;

      if (this.filters.searchQuery) {
        return result
          .filter((el) => el.name === this.filters.searchQuery)
          .map((el) => ({ ...el, coords: [el.geoLat, el.geoLon] }));
      }

      result = result.filter(
        (restaurant) => restaurant.capacity >= this.filters.maxCapacity
      );

      result = result.filter(
        (restaurant) => restaurant.averageCheck <= this.filters.maxAverageCheck
      );

      if (this.filters.cuisineId.length) {
        result = result.filter((restaurant) => {
          return restaurant.cuisines.some((el) => {
            return this.filters.cuisineId.includes(el);
          });
        });
      }

      if (this.filters.now) {
        const isPlaceOpen = (workingHours, time) => {
          if (!workingHours) {
            return false;
          }

          const openingTime = this.formatTime(workingHours[0]);
          const closingTime = this.formatTime(workingHours[1]);

          const currentTime = new Date(`2000-01-01T${time}`);
          const opening = new Date(`2000-01-01T${openingTime}`);
          const closing = new Date(`2000-01-01T${closingTime}`);

          return currentTime >= opening && currentTime <= closing;
        };

        result = result.filter((restaurant) => {
          const date = new Date();
          const currentTime = `${date.getHours()}:${date.getMinutes()}`;
          const currentWeekday = WEEKDAYS[date.getDay()];

          const targetWeekday = restaurant.workingTime.find((el) =>
            el.weekDays.includes(currentWeekday)
          );

          if (!targetWeekday) {
            return false;
          }

          return isPlaceOpen(targetWeekday?.time, currentTime);
        });
      }

      return result.map((el) => ({ ...el, coords: [el.geoLat, el.geoLon] }));
    },

    updateHandler(val) {
      this.filters[val.type] = val.value;
    },

    updateKitchenHandler(val) {
      if (this.filters.cuisineId.includes(val)) {
        this.filters.cuisineId = this.filters.cuisineId.filter(
          (el) => el !== val
        );
      } else {
        this.filters.cuisineId.push(+val);
      }
    },

    async resetHandler() {
      this.filters = {
        searchQuery: "",
        searchQueryMain: "",
        now: 0,
        nowMain: 0,
        cuisineId: [],
        maxCapacity: 1,
        maxAverageCheck: 3000,
      };
      await this.$store.dispatch("getClientRestaurants", {
        value: { city: "Екатеринбург", settlement: "" },
      });
      this.items = this.filteredItems();
      this.resetFilter();
    },

    formatTime(timeString) {
      const hour = timeString.slice(0, 2);
      const minute = timeString.slice(2);
      return `${hour}:${minute}`;
    },

    async resetFilter() {
      this.$store.commit({
        type: "resetCatFilter",
      });
      await this.$store.dispatch("getClientRestaurants", {
        value: { city: "Екатеринбург", settlement: "" },
      });
      this.items = this.filteredItems();
    },
  },

  data() {
    return {
      modal: "",
      items: [],
      filters: {
        searchQuery: "",
        searchQueryMain: "",
        now: 0,
        nowMain: 0,
        cuisineId: [],
        maxCapacity: 1,
        maxAverageCheck: 3000,
      },
    };
  },

  async mounted() {
    await this.$store.dispatch("getClientRestaurants", {
      value: { city: "Екатеринбург", settlement: "" },
    });
    this.$nextTick(() => {
      if (this.roleGroup === "biz") {
        this.$store.dispatch("openModal", {
          value: {
            component: ChangeRoleModal,
            props: null,
          },
        });
      }
    });
    this.items = this.filteredItems();
  },

  watch: {
    isFilled: {
      handler(val) {
        if (val) {
          this.filter();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    isNotices() {
      return this.$store.state.notices.isNotices;
    },
    filteredItemsMain() {
      let result = this.items;

      if (this.filters.searchQueryMain) {
        return result
          .filter(
            (el) =>
              el.name.toLowerCase() ===
                this.filters.searchQueryMain.toLowerCase() ||
              el.altName.toLowerCase() ===
                this.filters.searchQueryMain.toLowerCase()
          )
          .map((el) => ({ ...el, coords: [el.geoLat, el.geoLon] }));
      }

      if (this.filters.nowMain) {
        const isPlaceOpen = (workingHours, time) => {
          if (!workingHours) {
            return false;
          }

          const openingTime = this.formatTime(workingHours[0]);
          const closingTime = this.formatTime(workingHours[1]);

          const currentTime = new Date(`2000-01-01T${time}`);
          const opening = new Date(`2000-01-01T${openingTime}`);
          const closing = new Date(`2000-01-01T${closingTime}`);

          return currentTime >= opening && currentTime <= closing;
        };

        result = result.filter((restaurant) => {
          const date = new Date();
          const currentTime = `${date.getHours()}:${date.getMinutes()}`;
          const currentWeekday = WEEKDAYS[date.getDay()];

          const targetWeekday = restaurant.workingTime.find((el) =>
            el.weekDays.includes(currentWeekday)
          );

          if (!targetWeekday) {
            return false;
          }

          return isPlaceOpen(targetWeekday?.time, currentTime);
        });
      }

      return result.map((el) => ({ ...el, coords: [el.geoLat, el.geoLon] }));
    },
    isShown() {
      if (this.screenWidth !== "mobile") {
        return true;
      } else {
        return !this.clickedCatering;
      }
    },

    initItems() {
      return this.$store.state.clientRestaurants.items;
    },

    roleGroup() {
      return this.$store.getters.roleGroup;
    },

    clickedCatering() {
      return this.$route.params.catId;
    },
    screenWidth() {
      return this.$store.getters.screenType;
    },
    filterDate() {
      return this.$store.state.clientRestaurants.filter.date;
    },
    filterTime() {
      return this.$store.state.clientRestaurants.filter.time;
    },
    filterVisitors() {
      return this.$store.state.clientRestaurants.filter.visitors;
    },
    filterDuration() {
      return this.$store.state.clientRestaurants.filter.duration;
    },
    isFilled() {
      return this.$store.getters.isMapFilterFilled;
    },
  },
};
</script>

<style scoped>
.step-info {
  position: absolute;
  left: calc(50% - 320px);
  top: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  height: 72px;
  padding: 8px;
  gap: 8px;
  border-radius: 18px;
  box-shadow: 0 4px 16px 0 #00000014;

  z-index: 199999;

  @media screen and (max-width: 1300px) {
    display: none;
  }

  .container {
    background-color: #f6f6f6;
    width: 194px;
    padding: 8px;
    cursor: pointer;

    @include mobile {
      background-color: #fff;
      box-shadow: none;
    }
  }
}

.cross {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 12px;
  border: 2px solid #858585;
  cursor: pointer;
}
</style>
