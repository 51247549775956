<template>
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.35214 10.3648L2.35198 10.3645C1.73035 9.52806 1.32069 8.7911 1.09549 8.15099L1.09529 8.15042C0.86448 7.49606 0.75 6.83036 0.75 6.1499C0.75 4.76998 1.20436 3.6562 2.10533 2.75523C3.0063 1.85426 4.12008 1.3999 5.5 1.3999C6.25701 1.3999 6.97345 1.55919 7.65705 1.87917C8.34182 2.1997 8.93069 2.64993 9.42854 3.23564L10 3.90794L10.5715 3.23564C11.0693 2.64993 11.6582 2.1997 12.343 1.87917C13.0266 1.55919 13.743 1.3999 14.5 1.3999C15.8799 1.3999 16.9937 1.85426 17.8947 2.75523C18.7956 3.6562 19.25 4.76998 19.25 6.1499C19.25 6.83059 19.1358 7.49649 18.9055 8.15099C18.6796 8.79126 18.2695 9.52823 17.648 10.3646L17.6479 10.3648C17.0268 11.2012 16.1787 12.1605 15.0947 13.2446C14.0072 14.3321 12.6261 15.6307 10.9489 17.1418C10.9486 17.1421 10.9483 17.1424 10.948 17.1427L10 17.9926L9.05203 17.1427C9.0517 17.1424 9.05138 17.1421 9.05105 17.1418C7.3739 15.6307 5.99282 14.3321 4.90533 13.2446C3.8213 12.1605 2.97323 11.2012 2.35214 10.3648Z"
      :fill="color"
      stroke="#F18E35"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default {
  name: "IconEmptyFavorite",
  props: {
    color: {
      type: String,
      default: "#fff",
    },
  },
};
</script>

<style scoped></style>
