<template>
  <header>
    <div
      v-if="isMenu && isAuth"
      class="hamburger-lines"
      @click="onMenuClickHandler"
    >
      <span class="line line1"></span>
      <span class="line line2"></span>
      <span class="line line3"></span>
    </div>
    <LogoIcon @click="onLogoClickHandler" class="logo" />
    <div v-if="!isAuth" class="auth-buttons">
      <VButton small @click="onLoginClickHandler"> Войти </VButton>
      <VButton small @click="onRegistrationClickHandler" outline>
        Регистрация
      </VButton>
    </div>
    <div v-else class="account">
      <div
        v-if="role === 'MAJOR' || role === 'MARKETER'"
        class="balance"
        @click="isAccountBalanceTooltip = true"
      >
        <div class="rubble">
          <div><IconRubble /></div>
        </div>
        <span class="sum">{{ this.balance }} ₽</span>
      </div>
      <div
        v-if="role === 'CLIENT' && screenWidth !== 'mobile'"
        class="location"
      >
        <IconLocation />
        <span>Екатеринбург</span>
      </div>
      <div class="ring">
        <IconRing class="svg-ring" @click="onRingClickHandler" />
        <div class="ring_hasItems" v-if="unreadItems">
          <span v-if="unreadItems < 10">{{ unreadItems }}</span>
        </div>
      </div>
      <span v-if="screenWidth === 'desktop'" class="name">{{ name }}</span>
      <VImage
        :src="avatar || defaultAvatar"
        ref="avatar"
        class="avatar"
        @click="onAvatarClickHandler"
      />
      <HeaderTooltip
        v-if="isAccountTooltip"
        @close="isAccountTooltip = false"
      />
      <HeaderBalanceTooltip
        :balanceEntities="entityBalances"
        v-if="isAccountBalanceTooltip"
        @close="isAccountBalanceTooltip = false"
      />
    </div>
    <SingleNoticeList class="notices" />
  </header>
</template>

<script>
import defaultAvatar from "@/assets/images/defaultAvatar.png";
import SingleNoticeList from "@/components/notice/SingleNoticeList.vue";
import HeaderTooltip from "@/components/ui/header/HeaderTooltip.vue";
import VImage from "@/components/ui/VImage.vue";
import IconRing from "@/components/icons/IconRing.vue";
import IconLocation from "@/components/icons/IconLocation.vue";
import VButton from "@/components/ui/VButton.vue";
import AuthLoginModal from "@/components/modals/auth/AuthLoginModal.vue";
import AuthRegistrationModal from "@/components/modals/auth/AuthRegistrationModal.vue";
import IconRubble from "@/components/icons/IconRubble.vue";
import HeaderBalanceTooltip from "@/components/ui/header/HeaderBalanceTooltip.vue";
import LogoIcon from "@/components/icons/LogoIcon.vue";

export default {
  name: "Header",
  components: {
    LogoIcon,
    HeaderBalanceTooltip,
    IconRubble,
    VButton,
    IconLocation,
    IconRing,
    VImage,
    HeaderTooltip,
    SingleNoticeList,
  },

  data() {
    return {
      isAccountTooltip: false,
      isAccountBalanceTooltip: false,
      defaultAvatar,
    };
  },

  methods: {
    onRingClickHandler() {
      this.$store.commit("toggleNotices");
    },
    onMenuClickHandler() {
      this.$store.commit("toggleMenu");
    },
    onLoginClickHandler() {
      this.$store.dispatch("openModal", {
        value: {
          component: AuthLoginModal,
        },
      });
    },
    onRegistrationClickHandler() {
      this.$store.dispatch("openModal", {
        value: {
          component: AuthRegistrationModal,
        },
      });
    },
    onLogoClickHandler() {
      this.$router.push("/");
    },
    onAvatarClickHandler() {
      if (!this.isAccountTooltip) {
        this.isAccountTooltip = true;
      }
    },
  },

  watch: {
    isAccountTooltip(val) {
      if (val) {
        this.isAccountBalanceTooltip = false;
      }
    },
    isAccountBalanceTooltip(val) {
      if (val) {
        this.isAccountTooltip = false;
      }
    },
  },

  computed: {
    isMenu() {
      return this.screenWidth !== "desktop";
    },
    screenWidth() {
      return this.$store.getters.screenType;
    },
    isAuth() {
      return this.$store.state.general.auth.isAuth;
    },
    name() {
      if (this.role === "CLIENT") {
        return this.$store.state.general.userInfo?.firstName;
      } else {
        return this.$store.state.general.userInfo?.name;
      }
    },
    role() {
      return this.$store.getters.role;
    },
    roleGroup() {
      return this.$store.getters.roleGroup;
    },
    avatar() {
      return this.$store.state.general.userInfo?.avatar;
    },
    unreadItems() {
      return this.$store.state.notices.unreadItems.length;
    },
    balance() {
      return this.$store.state.general.userInfo?.balance;
    },
    entityBalances() {
      return this.$store.state.general.userInfo?.entityBalances;
    },
  },
};
</script>

<style scoped lang="scss">
header {
  display: flex;
  align-items: center;
  position: relative;
  height: 68px;
  padding: 10px 24px;
  background: #ffffff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  z-index: 11111111;

  @include mobile {
    padding-right: 16px;
  }
}

.account {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  user-select: none;
  z-index: 111111;
}

.ring {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  cursor: pointer;

  @include tablet {
    margin-right: 16px;
  }

  &_hasItems {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    transform: translate(50%, -50%);
    border-radius: 50%;
    background-color: #f18e35;

    span {
      color: #ffffff;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  cursor: pointer;
}

.location {
  display: flex;
  align-items: center;
  margin-right: 24px;

  @include tablet {
    margin-right: 16px;
  }

  svg {
    margin-right: 4px;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-decoration-line: underline;
  }
}

.name {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-right: 16px;
}

.avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  cursor: pointer;
}

.logo {
  width: 70px;
  height: 40px;
  cursor: pointer;
}

.auth-buttons {
  margin-left: auto;
  z-index: 1;

  button:first-child {
    margin-right: 8px;
  }
}

.hamburger-lines {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 12px;
  margin: 0 12px 0 4px;
}

.hamburger-lines .line {
  display: block;
  height: 2px;
  width: 18px;
  border-radius: 10px;
  background: #0e2431;
}

.balance {
  display: flex;
  height: 40px;
  margin-right: 24px;
  border-radius: 100px;
  background-color: #fef6e8;
  cursor: pointer;
}

.rubble {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-bottom: 16px;
  background-color: #f18e35;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: linear-gradient(
      147.26deg,
      #ffffff 39.95%,
      rgba(255, 255, 255, 0) 115.22%
    );
  }

  svg {
    height: 16px;
    width: 16px;
  }
}

.sum {
  padding: 9px 24px 9px 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  color: #f18e35;
}
</style>
