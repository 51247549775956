<template>
  <svg
    width="30"
    height="28"
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      v-if="isFilled"
      d="M15.0004 23.4583L8.0837 27.625C7.77814 27.8194 7.4587 27.9028 7.12536 27.875C6.79203 27.8472 6.50036 27.7361 6.25036 27.5417C6.00036 27.3472 5.80592 27.1044 5.66703 26.8133C5.52814 26.5211 5.50036 26.1944 5.58369 25.8333L7.41703 17.9583L1.29203 12.6667C1.01425 12.4167 0.840917 12.1317 0.772028 11.8117C0.702028 11.4928 0.722584 11.1806 0.833695 10.875C0.944806 10.5694 1.11147 10.3194 1.33369 10.125C1.55592 9.93056 1.86147 9.80556 2.25036 9.75L10.3337 9.04167L13.4587 1.625C13.5976 1.29167 13.8131 1.04167 14.1054 0.875C14.3965 0.708333 14.6948 0.625 15.0004 0.625C15.3059 0.625 15.6048 0.708333 15.897 0.875C16.1881 1.04167 16.4031 1.29167 16.542 1.625L19.667 9.04167L27.7504 9.75C28.1393 9.80556 28.4448 9.93056 28.667 10.125C28.8893 10.3194 29.0559 10.5694 29.167 10.875C29.2781 11.1806 29.2993 11.4928 29.2304 11.8117C29.1604 12.1317 28.9865 12.4167 28.7087 12.6667L22.5837 17.9583L24.417 25.8333C24.5004 26.1944 24.4726 26.5211 24.3337 26.8133C24.1948 27.1044 24.0004 27.3472 23.7504 27.5417C23.5004 27.7361 23.2087 27.8472 22.8754 27.875C22.542 27.9028 22.2226 27.8194 21.917 27.625L15.0004 23.4583Z"
      fill="#FFED00"
    />
    <path
      v-else
      d="M15.0004 23.4583L8.0837 27.625C7.77814 27.8194 7.4587 27.9028 7.12536 27.875C6.79203 27.8472 6.50036 27.7361 6.25036 27.5417C6.00036 27.3472 5.80592 27.1044 5.66703 26.8133C5.52814 26.5211 5.50036 26.1944 5.58369 25.8333L7.41703 17.9583L1.29203 12.6667C1.01425 12.4167 0.840917 12.1317 0.772028 11.8117C0.702028 11.4928 0.722584 11.1806 0.833695 10.875C0.944806 10.5694 1.11147 10.3194 1.33369 10.125C1.55592 9.93056 1.86147 9.80556 2.25036 9.75L10.3337 9.04167L13.4587 1.625C13.5976 1.29167 13.8131 1.04167 14.1054 0.875C14.3965 0.708333 14.6948 0.625 15.0004 0.625C15.3059 0.625 15.6048 0.708333 15.897 0.875C16.1881 1.04167 16.4031 1.29167 16.542 1.625L19.667 9.04167L27.7504 9.75C28.1393 9.80556 28.4448 9.93056 28.667 10.125C28.8893 10.3194 29.0559 10.5694 29.167 10.875C29.2781 11.1806 29.2993 11.4928 29.2304 11.8117C29.1604 12.1317 28.9865 12.4167 28.7087 12.6667L22.5837 17.9583L24.417 25.8333C24.5004 26.1944 24.4726 26.5211 24.3337 26.8133C24.1948 27.1044 24.0004 27.3472 23.7504 27.5417C23.5004 27.7361 23.2087 27.8472 22.8754 27.875C22.542 27.9028 22.2226 27.8194 21.917 27.625L15.0004 23.4583Z"
      fill="#D6D6D6"
    />
  </svg>
</template>

<script>
export default {
  name: "IconBigStar",

  props: {
    isFilled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
