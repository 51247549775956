<template>
  <div
    class="image"
    :class="classes"
    :style="{ backgroundImage: `url(${image})` }"
  >
    <IconCloseImage
      class="cross"
      v-if="isDeletable"
      @click="$emit('image-deleted')"
    />
  </div>
</template>

<script>
import IconCloseImage from "@/components/icons/IconCloseImage";
import Logo from "@/assets/images/logo.png";

export default {
  name: "VImage",
  components: { IconCloseImage },
  emits: ["image-deleted"],
  props: {
    class: {
      type: String,
      default: "",
    },
    src: {
      type: String,
      default: "",
    },
    isDeletable: {
      type: Boolean,
    },
  },

  data() {
    return { logo: Logo };
  },

  computed: {
    classes() {
      return [this.class, !this.src ? "is-default" : ""];
    },
    image() {
      return this.src || this.logo;
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  transform: translate3d(0px, 0px, 0px);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  &.is-default {
    background-size: 75%;
    filter: grayscale(1);
    background-color: #ebebeb;
  }
}

.cross {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
}
</style>
