<template>
  <div class="container-button">
    <div
      class="button _prev"
      :class="{ _disabled: modelValue === minValue }"
      @click="decrement"
    >
      <IconBookingCounterArrow
        :color="modelValue === minValue ? '#F18E35' : '#ffffff'"
      />
    </div>
    <EditableBookingSpan :value="modelValue" @update="updateInputValue" />
    <div
      class="button _next"
      :class="{ _disabled: modelValue === maxValue }"
      @click="increment"
    >
      <IconBookingCounterArrow
        :color="modelValue === maxValue ? '#F18E35' : '#ffffff'"
      />
    </div>
  </div>
</template>

<script>
import IconBookingCounterArrow from "@/components/icons/booking/IconBookingCounterArrow.vue";
import EditableBookingSpan from "@/components/ui/EditableBookingSpan.vue";

export default {
  name: "Counter",
  components: { EditableBookingSpan, IconBookingCounterArrow },

  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
    minValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 100,
    },
    stepValue: {
      type: Number,
      default: 1,
    },
  },

  methods: {
    updateInputValue(val) {
      let value = +val.replace(/\D/g, "");
      if (value < this.minValue) {
        value = this.minValue;
      }
      if (value > this.maxValue) {
        value = this.maxValue;
      }
      this.$emit("update:modelValue", value);
    },
    decrement() {
      let value = this.modelValue;
      value -= this.stepValue;
      if (value < this.minValue) {
        value = this.minValue;
      }
      this.$emit("update:modelValue", value);
    },
    increment() {
      let value = this.modelValue;
      value += this.stepValue;
      if (value > this.maxValue) {
        value = this.maxValue;
      }
      this.$emit("update:modelValue", value);
    },
  },

  mounted() {
    this.currentValue = this.modelValue;
  },
};
</script>

<style lang="scss" scoped>
.container-button {
  display: flex;
  align-items: center;
  width: 300px;
  padding: 4px;
  margin: 0 auto;
  border-radius: 100px;
  background: #fef6e8;
  user-select: none;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background: #f18e35;
  cursor: pointer;

  &._prev {
    margin-right: auto;
  }
  &._next {
    margin-left: auto;
    transform: rotate(180deg);
  }

  &._disabled {
    background: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  }
}
</style>
