<template>
  <div class="container" v-if="items.length">
    <SingleNotice
      v-for="item in items"
      :key="item.id"
      :data="item"
      @close="readItem(item.id)"
      @hide="hideItem(item.id)"
    />
  </div>
  <div class="container _business" v-if="businessItems.length">
    <SingleBusinessNotice
      v-for="item in businessItems"
      :key="item.id"
      :data="item"
      @close="readItem(item.id)"
      @hide="hideItem(item.id)"
    />
  </div>
</template>

<script>
import SingleNotice from "@/components/notice/SingleNotice.vue";
import { API } from "@/assets/js/api/api";
import SingleBusinessNotice from "@/components/notice/SingleBusinessNotice.vue";

export default {
  name: "SingleNoticeList",
  components: { SingleBusinessNotice, SingleNotice },
  computed: {
    items() {
      return this.$store.state.notices.unreadPushItems.filter(
        (el) => el.deliveryType === 1
      );
    },
    businessItems() {
      return this.$store.state.notices.unreadItems.filter(
        (el) => el.deliveryType === 2
      );
    },
    roleGroup() {
      return this.$store.getters.roleGroup;
    },
  },
  methods: {
    async readItem(val) {
      try {
        await API.notices.markAsRead(
          {
            notifications: [val],
            userID: this.$store.state.general.userInfo.id,
          },
          this.roleGroup
        );
        this.$store.commit({ type: "clearNotification", value: val });
      } catch (e) {
        console.log(e);
      }
    },
    hideItem(val) {
      this.$store.commit({ type: "clearPushNotification", value: val });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 76px;
  right: 54px;
  z-index: 10000000000000;
  gap: 8px;

  &._business {
    width: calc(100% - 552px);
    right: initial;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
