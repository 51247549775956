<template>
  <Teleport to="body">
    <Transition name="modal-fade">
      <div
        class="modal-wrapper"
        @click.self="onClickOutsideHandler"
        v-if="store.state.modal?.modalState.component"
        aria-modal="true"
        role="dialog"
        tabindex="-1"
        ref="modal"
      >
        <component
          :is="store.state.modal?.modalState.component"
          v-bind="store.state.modal?.modalState.props"
        />
      </div>
    </Transition>
  </Teleport>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const store = useStore();
const route = useRoute();

const modal = ref(null);

function keydownListener(event) {
  if (event.key === "Escape") store.dispatch("closeModal");
}

onMounted(() => {
  document.addEventListener("keydown", keydownListener);
});

onUnmounted(() => {
  document.removeEventListener("keydown", keydownListener);
});

const isEvisoBusinessAuth = computed(() => {
  return route.name === "auth-biz" || route.name === "auth-eviso";
});

const onClickOutsideHandler = () => {
  if (!isEvisoBusinessAuth.value) {
    store.dispatch("closeModal");
  }
};
</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;

  z-index: 9999999999;

  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);

  display: flex;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: 0.25s ease all;
}
</style>
