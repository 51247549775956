import axios from "axios";
import { prodCheck } from "@/assets/js/utils/prodCheck";
import { BASE_URL } from "@/assets/js/api/baseUrl";

const instance = axios.create({
  baseURL: prodCheck() ? "/api/v1/" : BASE_URL,
  withCredentials: true,
});

export const API_WITHOUT_GUARDS = {
  client: {
    login(data) {
      return instance.post("client/login", data, {
        headers: { "Content-Type": "application/json" },
      });
    },
    register(data) {
      return instance.post("client/register", data);
    },
    verifySMS(data) {
      return instance.post("client/sms-verifier", data);
    },
    verifySMSResend(data) {
      return instance.post("client/sms-resend", data, {
        headers: { "Content-Type": "application/json" },
      });
    },
    verifySMSResendPasswordReset(data) {
      return instance.post("client/reset/sms-resend", data);
    },
    resetPassword(data) {
      return instance.post("client/reset", data);
    },
    setPassword(data) {
      return instance.post("client/reset/set-password", data);
    },
    getMapCatering(data) {
      return instance.post(`catering/cat/map`, data, {
        headers: { "Content-Type": "application/json" },
      });
    },
    getMapCateringWithFilter(data) {
      return instance.post(`cat-filter`, data, {
        headers: { "Content-Type": "application/json" },
      });
    },
    getCateringByID(data) {
      return instance.get(`catering/cat/${data}`);
    },
  },
  eviso: {
    login(data) {
      return instance.post("eviso/login", data, {
        headers: { "Content-Type": "application/json" },
      });
    },
    resetPassword(data) {
      return instance.post("eviso/reset", data);
    },
    recoverPassword(data) {
      return instance.post("org/recover-password", data);
    },
    setPassword(data) {
      return instance.post("eviso/reset/set-password", data);
    },
    verifySMSResend(data) {
      return instance.post("eviso/sms-resend", data, {
        headers: { "Content-Type": "application/json" },
      });
    },
    verifySMSResendPasswordReset(data) {
      return instance.post("eviso/reset/sms-resend", data);
    },
  },
  business: {
    login(data) {
      return instance.post("biz/login", data, {
        headers: { "Content-Type": "application/json" },
      });
    },
    resetPassword(data) {
      return instance.post("biz/reset", data);
    },
    recoverPassword(data) {
      return instance.post("biz/recover-password", data);
    },
    verifySMSResend(data) {
      return instance.post("biz/sms-resend", data, {
        headers: { "Content-Type": "application/json" },
      });
    },
    verifySMSResendPasswordReset(data) {
      return instance.post("biz/reset/sms-resend", data);
    },
  },
  reviews: {
    getCateringItems(id) {
      return instance.get(`review/cat/${id}`);
    },
    getCateringItemsMap(id) {
      return instance.get(`review/cat/${id}/map`);
    },
    getImages(id) {
      return instance.get(`files/rev/${id}`);
    },
    getCommentPhotos(id, cid) {
      return instance.get(`files/rev/${id}/comment/${cid}`);
    },
  },
  catering: {
    getImagesByEviso(id) {
      return instance.get(`files/cat/${id}`);
    },
    getLogoByEviso(id) {
      return instance.get(`files/cat/${id}/logo`);
    },
    getScheme(cateringID) {
      return instance.get(`catering/seating-map/${cateringID}`);
    },
  },
  user: {
    getAvatar(id) {
      return instance.get(`files/avatar/${id}`);
    },
  },
  booking: {
    bookTable(data) {
      return instance.post(`book`, data, {
        headers: { "Content-Type": "application/json" },
      });
    },
    getWidgetCaterings(data) {
      return instance.post(`widget/catering`, data, {
        headers: { "Content-Type": "application/json" },
      });
    },
  },
};
