<template>
  <div class="contacts-accordion" :class="{ _opened: opened, _range: range }">
    <div class="contacts-accordion__head" @click="clickHandler">
      <div class="contacts-accordion__title">
        <slot name="head"> </slot>
      </div>
      <IconAccordionArrow />
    </div>
    <div
      ref="body"
      class="contacts-accordion__body"
      :class="{ _opened: opened }"
    >
      <slot name="body"> </slot>
    </div>
  </div>
</template>

<script>
import IconAccordionArrow from "@/components/icons/IconAccordionArrow";
export default {
  name: "VAccordion",
  components: { IconAccordionArrow },

  props: {
    range: [Boolean],
  },
  data() {
    return {
      opened: true,
    };
  },

  methods: {
    clickHandler() {
      this.opened = !this.opened;
    },
  },
};
</script>

<style scoped lang="scss">
.contacts-accordion {
  $block: &;

  position: relative;

  &._opened {
    &._range {
      #{$block}__head {
        padding: 0 0 50px 0;
      }
    }

    #{$block}__head > svg {
      transform: rotate(0deg);
    }
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
  }

  &__head {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 0 16px 0;
    cursor: pointer;

    & > svg {
      width: 14px;
      height: 14px;
      transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      transform: rotate(180deg);
    }
  }

  &__head-img {
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-right: 20px;
  }

  &__title {
    margin-right: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    user-select: none;

    @include mobile {
      font-weight: 600;
    }
  }

  &__body {
    display: none;
    transition: max-height 0.5s;

    &._opened {
      display: block;
    }
  }

  &__transport {
    padding: 0 42px 30px;
  }
}
</style>
