<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.9997 70.8333C51.1802 70.8333 52.1705 70.4333 52.9705 69.6333C53.7677 68.8361 54.1663 67.8472 54.1663 66.6666C54.1663 65.4861 53.7677 64.4958 52.9705 63.6958C52.1705 62.8986 51.1802 62.5 49.9997 62.5C48.8191 62.5 47.8302 62.8986 47.033 63.6958C46.233 64.4958 45.833 65.4861 45.833 66.6666C45.833 67.8472 46.233 68.8361 47.033 69.6333C47.8302 70.4333 48.8191 70.8333 49.9997 70.8333ZM45.833 50C45.833 52.3012 47.6985 54.1666 49.9997 54.1666C52.3009 54.1666 54.1663 52.3012 54.1663 50V33.3333C54.1663 31.0321 52.3009 29.1666 49.9997 29.1666C47.6985 29.1666 45.833 31.0321 45.833 33.3333V50ZM49.9997 91.6666C44.2358 91.6666 38.8191 90.5722 33.7497 88.3833C28.6802 86.1972 24.2705 83.2291 20.5205 79.4791C16.7705 75.7291 13.8025 71.3194 11.6163 66.25C9.42745 61.1805 8.33301 55.7639 8.33301 50C8.33301 44.2361 9.42745 38.8194 11.6163 33.75C13.8025 28.6805 16.7705 24.2708 20.5205 20.5208C24.2705 16.7708 28.6802 13.8014 33.7497 11.6125C38.8191 9.42637 44.2358 8.33331 49.9997 8.33331C55.7636 8.33331 61.1802 9.42637 66.2497 11.6125C71.3191 13.8014 75.7288 16.7708 79.4788 20.5208C83.2288 24.2708 86.1969 28.6805 88.383 33.75C90.5719 38.8194 91.6663 44.2361 91.6663 50C91.6663 55.7639 90.5719 61.1805 88.383 66.25C86.1969 71.3194 83.2288 75.7291 79.4788 79.4791C75.7288 83.2291 71.3191 86.1972 66.2497 88.3833C61.1802 90.5722 55.7636 91.6666 49.9997 91.6666ZM49.9997 83.3333C59.3052 83.3333 67.1872 80.1041 73.6455 73.6458C80.1038 67.1875 83.333 59.3055 83.333 50C83.333 40.6944 80.1038 32.8125 73.6455 26.3541C67.1872 19.8958 59.3052 16.6666 49.9997 16.6666C40.6941 16.6666 32.8122 19.8958 26.3538 26.3541C19.8955 32.8125 16.6663 40.6944 16.6663 50C16.6663 59.3055 19.8955 67.1875 26.3538 73.6458C32.8122 80.1041 40.6941 83.3333 49.9997 83.3333Z"
      fill="#F18E35"
    />
  </svg>
</template>

<script>
export default {
  name: "IconCheckAwaiting",
};
</script>
