<template>
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 14.8333C19 15.2754 18.8104 15.6993 18.4728 16.0118C18.1352 16.3244 17.6774 16.5 17.2 16.5H2.8C2.32261 16.5 1.86477 16.3244 1.52721 16.0118C1.18964 15.6993 1 15.2754 1 14.8333V3.16667C1 2.72464 1.18964 2.30072 1.52721 1.98816C1.86477 1.67559 2.32261 1.5 2.8 1.5H7.3L9.1 4H17.2C17.6774 4 18.1352 4.17559 18.4728 4.48816C18.8104 4.80072 19 5.22464 19 5.66667V14.8333Z"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconDocuments",

  props: {
    color: {
      type: String,
      default: "#F18E35",
    },
  },
};
</script>

<style scoped></style>
