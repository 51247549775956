/* eslint-disable */
import store from "@/store";
import router from "@/router";

var map;

function checkDuplicateObjects(arr) {
  if (arr.length === 0) {
    return false;
  }

  for (var i = 1; i < arr.length; i++) {
    var obj1 = JSON.stringify(arr[i]._latlng);
    var obj2 = JSON.stringify(arr[0]._latlng);

    if (obj1 !== obj2) {
      return false;
    }
  }

  return true;
}

// var markerLayer = L.layerGroup();

var markerLayer = L.markerClusterGroup({
  spiderfyOnMaxZoom: false,
  showCoverageOnHover: false,
  // zoomToBoundsOnClick: false,
});

const LeafIcon = L.Icon.extend({
  options: {
    iconSize: [21, 27],
  },
});

const customMarker = new LeafIcon({
  iconUrl:
    (process.env.NODE_ENV === "production" ? "/static/" : "/") +
    "leaflet/images/marker.png",
});

export function initMap(
  items,
  refs,
  isObject = true,
  zoom = 11,
  center = [56.834463, 60.600582]
) {
  map = L.map("map").setView(center, zoom);

  map.options.minZoom = 11;

  let tileErrors = 0;

  const myLayer = L.tileLayer(
    "https://tile.openstreetmap.org/{z}/{x}/{y}.png"
  ).addTo(map);

  myLayer.on("tileerror", function () {
    tileErrors = tileErrors + 1;
    if (tileErrors === 7) {
      router.push("/restaurants");
      tileErrors = 0;
    }
  });

  map.on("dragstart", function () {
    tileErrors = 0;
  });

  myLayer.addTo(map);

  if (!items.length) {
    return;
  }

  if (isObject) {
    addMarkersToMap(items, refs);
  } else {
    var markerInit;
    items.forEach((el) => {
      markerInit = L.marker(el.coords, { icon: customMarker }).addTo(map);
      map.addLayer(markerInit);
    });
    map.on("click", function (e) {
      map.removeLayer(markerInit);
      markerInit = L.marker(e.latlng, {
        icon: customMarker,
      }).addTo(map);
      store.commit({
        type: "setNewCoords",
        value: [e.latlng.lat, e.latlng.lng],
      });
    });
  }
}

export function addMarkersToMap(items, refs) {
  markerLayer.clearLayers();

  if (!items.length || !map || !refs) return;

  let markers = [];

  items.forEach((el) => {
    const ref = refs?.$el;
    const popup = L.popup({ autoPan: true, keepInView: false }).setContent(
      () => {
        ref.style.display = "flex";
        return ref;
      }
    );

    const marker = L.marker(el.coords, {
      icon: customMarker,
      catId: el.id,
    }).bindPopup(popup);

    let isClicked = false;

    marker.on({
      mouseover: function () {
        this._popup.options.autoPan = true;
        if (!isClicked) {
          this.setIcon(customMarker);
          this.openPopup();
          this._popup.options.autoPan = false;
          store.commit({
            type: "setHoveredCatering",
            value: el.id,
          });
        }
      },
      mouseout: function () {
        if (!isClicked) {
          this.setIcon(customMarker);
          setTimeout(() => {
            this.closePopup();
            this._popup.options.autoPan = false;
          }, 5000);
        }
      },
      click: function () {
        router.push(`/${el.id}`);
      },
    });

    map.on({
      click: function () {
        isClicked = false;
        marker.setIcon(customMarker);
        marker.bindPopup(() => {
          ref.style.display = "flex";
          return ref;
        });
        router.push(`/`);
        store.commit({
          type: "setClickedCluster",
          value: null,
        });
      },
      popupclose: function () {
        isClicked = false;
        marker.bindPopup(() => {
          ref.style.display = "flex";
          return ref;
        });
      },
    });

    markers.push(marker);
  });

  for (var i = 0; i < markers.length; i++) {
    markerLayer.addLayer(markers[i]);
  }

  markerLayer.on("clusterclick", function (a) {
    if (
      a.layer._zoom === 18 ||
      checkDuplicateObjects(a.layer.getAllChildMarkers())
    ) {
      router.push("/");
      store.commit({
        type: "setClickedCluster",
        value: a.layer.getAllChildMarkers(),
      });
    }
  });

  markerLayer.addTo(map);
}

export function removeMap() {
  if (!map) return;
  map.remove();
}
