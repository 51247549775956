<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.39752 11.2575C8.44814 11.8652 7.31958 12.2175 6.10873 12.2175C2.73497 12.2175 0 9.48246 0 6.10873C0 2.73499 2.73497 0 6.10873 0C9.48249 0 12.2175 2.73499 12.2175 6.10873C12.2175 7.76646 11.5571 9.26994 10.4852 10.3705L12.9229 12.8082C13.1955 13.0809 13.1955 13.5229 12.9229 13.7955C12.6502 14.0682 12.2082 14.0682 11.9355 13.7955L9.39752 11.2575ZM10.6466 6.10873C10.6466 8.61495 8.61495 10.6467 6.10873 10.6467C3.6025 10.6467 1.57082 8.61495 1.57082 6.10873C1.57082 3.6025 3.6025 1.57081 6.10873 1.57081C8.61495 1.57081 10.6466 3.6025 10.6466 6.10873Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconSearch",
  props: {
    color: {
      type: String,
      default: "#ADADAD",
    },
  },
};
</script>

<style scoped></style>
