<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.6623 3.2186C9.96831 2.92713 10.4645 2.92713 10.7705 3.21861C11.0765 3.51008 11.0765 3.98265 10.7705 4.27412L6.33773 8.4962C6.03171 8.78767 5.53555 8.78767 5.22954 8.4962C4.92352 8.20472 4.92352 7.73215 5.22954 7.44068L9.6623 3.2186Z"
      fill="white"
    />
    <path
      d="M10.7705 11.7259C11.0765 12.0174 11.0765 12.4899 10.7705 12.7814C10.4644 13.0729 9.96829 13.0729 9.66227 12.7814L5.22951 8.55932C4.9235 8.26785 4.9235 7.79528 5.22951 7.5038C5.53553 7.21233 6.03168 7.21233 6.3377 7.5038L10.7705 11.7259Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconItemArrow",
};
</script>

<style scoped></style>
