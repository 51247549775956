<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="8" fill="#222222" fill-opacity="0.5" />
    <path
      d="M4.20935 5.2202C3.93022 4.94106 3.93022 4.48849 4.20935 4.20936C4.48849 3.93022 4.94105 3.93022 5.22019 4.20936L11.7906 10.7798C12.0698 11.0589 12.0698 11.5115 11.7906 11.7906C11.5115 12.0698 11.0589 12.0698 10.7798 11.7906L4.20935 5.2202Z"
      fill="white"
    />
    <path
      d="M10.7798 4.20935C11.0589 3.93022 11.5115 3.93022 11.7906 4.20935C12.0698 4.48849 12.0698 4.94105 11.7906 5.22019L5.22019 11.7906C4.94106 12.0698 4.48849 12.0698 4.20935 11.7906C3.93022 11.5115 3.93022 11.0589 4.20935 10.7798L10.7798 4.20935Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "IconCloseImage",
};
</script>

<style scoped></style>
