<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="hourglass">
      <path
        id="Vector"
        d="M10.6673 26.6665H21.334V22.6665C21.334 21.1998 20.8118 19.9443 19.7673 18.8998C18.7229 17.8554 17.4673 17.3332 16.0007 17.3332C14.534 17.3332 13.2784 17.8554 12.234 18.8998C11.1895 19.9443 10.6673 21.1998 10.6673 22.6665V26.6665ZM16.0007 14.6665C17.4673 14.6665 18.7229 14.1443 19.7673 13.0998C20.8118 12.0554 21.334 10.7998 21.334 9.33317V5.33317H10.6673V9.33317C10.6673 10.7998 11.1895 12.0554 12.234 13.0998C13.2784 14.1443 14.534 14.6665 16.0007 14.6665ZM6.66732 29.3332C6.28954 29.3332 5.97287 29.2054 5.71732 28.9498C5.46176 28.6943 5.33398 28.3776 5.33398 27.9998C5.33398 27.6221 5.46176 27.3054 5.71732 27.0498C5.97287 26.7943 6.28954 26.6665 6.66732 26.6665H8.00065V22.6665C8.00065 21.3109 8.31732 20.0387 8.95065 18.8498C9.58398 17.6609 10.4673 16.7109 11.6007 15.9998C10.4673 15.2887 9.58398 14.3387 8.95065 13.1498C8.31732 11.9609 8.00065 10.6887 8.00065 9.33317V5.33317H6.66732C6.28954 5.33317 5.97287 5.20539 5.71732 4.94984C5.46176 4.69428 5.33398 4.37762 5.33398 3.99984C5.33398 3.62206 5.46176 3.30539 5.71732 3.04984C5.97287 2.79428 6.28954 2.6665 6.66732 2.6665H25.334C25.7118 2.6665 26.0284 2.79428 26.284 3.04984C26.5395 3.30539 26.6673 3.62206 26.6673 3.99984C26.6673 4.37762 26.5395 4.69428 26.284 4.94984C26.0284 5.20539 25.7118 5.33317 25.334 5.33317H24.0007V9.33317C24.0007 10.6887 23.684 11.9609 23.0506 13.1498C22.4173 14.3387 21.534 15.2887 20.4007 15.9998C21.534 16.7109 22.4173 17.6609 23.0506 18.8498C23.684 20.0387 24.0007 21.3109 24.0007 22.6665V26.6665H25.334C25.7118 26.6665 26.0284 26.7943 26.284 27.0498C26.5395 27.3054 26.6673 27.6221 26.6673 27.9998C26.6673 28.3776 26.5395 28.6943 26.284 28.9498C26.0284 29.2054 25.7118 29.3332 25.334 29.3332H6.66732Z"
        fill="#F18E35"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconBookingDuration",
  props: {
    color: {
      type: String,
      default: "#F18E35",
    },
  },
};
</script>

<style scoped></style>
