<template>
  <perfect-scrollbar
    class="wrapper"
    :class="{ _restaurants: restaurants }"
    v-click-outside="clickOutside"
    v-if="isLoaded"
  >
    <div class="nav">
      <IconArrowBack @click="onArrowClickHandler" />
    </div>
    <div class="swiper">
      <template v-if="photos.length">
        <div class="swiper mySwiper" ref="swiper">
          <div class="swiper-wrapper">
            <VImage
              v-for="(photo, index) in photos"
              :key="photo.url"
              :src="photo.url"
              @click="openModal(photos, index)"
              class="image swiper-slide"
            />
          </div>
          <div ref="next" class="button-next" @click.stop>
            <IconItemArrow />
          </div>
          <div ref="prev" class="button-prev" @click.stop>
            <IconItemArrow />
          </div>
        </div>
      </template>
      <div v-else class="default-image">
        <img src="../../../assets/images/logo.png" :alt="details.name" />
      </div>
      <IconEmptyFavorite
        v-if="role === 'CLIENT'"
        class="favorite"
        :color="isFav ? '#F18E35' : '#fff'"
        @click="favoriteToggle"
      />
    </div>
    <div class="description">
      <div class="main-info">
        <VImage v-if="logo" :src="logo" class="main-info-logo" />
        <VImage v-else :src="defaultLogo" class="main-info-default-logo" />
        <div class="main-info-title">
          <p class="main-info-title-name">{{ details.name }}</p>
          <p class="main-info-title-type">{{ getType(details.cfTypeId) }}</p>
          <VStars is-number :value="stars" class="stars" />
        </div>
      </div>
      <div class="check">
        <span>Средний чек </span>
        <span class="check-bold">{{ details.averageCheck }} ₽</span>
      </div>
      <div class="details">
        <div class="details-address" v-if="details.address">
          <div class="svg-container">
            <IconGeoMarker />
          </div>
          <span>{{ details.address }}</span>
        </div>
        <div class="details-time">
          <div class="svg-container">
            <IconWatch />
          </div>
          <span class="details-tableu-text" v-if="isOpen[1]">
            {{ isOpen[1] }}
          </span>
          <div class="details-tableu" @click="isSchedule = !isSchedule">
            <span class="details-tableu-open">
              {{ isOpen[0] ? "Открыто" : "Закрыто" }}
            </span>
            <IconSelectArrow
              color="#F18E35"
              :style="{ transform: isSchedule ? 'rotate(180deg)' : '' }"
            />
          </div>
        </div>
        <div v-if="isSchedule" class="details-working-hours">
          <div
            v-for="(day, index) in updatedWeekdays(details.workingTime)"
            :key="index"
            class="details-working-hours-row"
            :class="{
              'details-working-hours-weekend':
                day.weekDays[0] === 'SUN' || day.weekDays[0] === 'SAT',
            }"
          >
            <div class="details-working-hours-label">
              {{ getRussianWeekday(day.weekDays[0]) }}
            </div>
            <div v-if="day.time">
              {{ getTime(day.time[0]) }} — {{ getTime(day.time[1]) }}
            </div>
            <div v-else>Закрыто</div>
          </div>
        </div>
        <div class="details-phone" v-if="details.phone">
          <div class="svg-container">
            <IconPhone />
          </div>
          <span>{{ details.phone }}</span>
        </div>
        <div class="details-link" v-if="details.site">
          <div class="svg-container">
            <IconWeb />
          </div>
          <a :href="formatLink(details.site)" target="_blank">
            {{ details.site }}
          </a>
        </div>
      </div>
      <div class="features" v-if="details.specificities?.length">
        <div class="features-title">Особенности</div>
        <div class="features-blocks">
          <div v-for="feature in details.specificities" :key="feature">
            {{ getFeature(feature) }}
          </div>
        </div>
      </div>
      <div class="kitchens" v-if="details.cuisines?.length">
        <div class="kitchens-title">Кухни</div>
        <div class="kitchens-blocks">
          <div v-for="cuisine in details.cuisines" :key="cuisine">
            {{ getCuisines(cuisine) }}
          </div>
        </div>
      </div>
      <div class="check">
        <span>Вместимость: </span>
        <span class="check-bold">{{ details.capacity }} человек</span>
      </div>
      <div class="reviews">
        <div class="reviews-header">
          Отзывы: <span>{{ reviews.length }}</span>
        </div>
        <div v-if="reviews.length" class="reviews-container">
          <Review
            v-for="review in currentReviews"
            :key="review.Id"
            :review="review"
            @new-review="addReview"
          />
          <p class="more" v-if="page < pagesCount" @click.stop="addReviews">
            Ещё отзывы
          </p>
        </div>
        <div v-else class="empty-container">
          <IconEmptyStar />
          <p class="question">Были в этом месте?</p>
          <p class="action">
            Поделитесь впечатлениями и оставьте первый отзыв!
          </p>
        </div>
      </div>
      <div class="btns">
        <VButton
          class="btn"
          outline
          @mouseenter="btnHovered = true"
          @mouseleave="btnHovered = false"
          @click="addReview"
          :disabled="reviewButtonDisabled"
        >
          <template v-if="!reviewButtonDisabled" #leftAddon>
            <IconPen :color="btnHovered ? '#ffffff' : '#F18E35'" />
          </template>
          <template v-else #leftAddon>
            <IconPen :color="'#F18E35'" />
          </template>
          <template #default>Написать отзыв</template>
        </VButton>
        <VButton class="btn" @click="book" :disabled="isDisabled">
          Забронировать стол
        </VButton>
      </div>
    </div>
  </perfect-scrollbar>
</template>

<script>
import VButton from "@/components/ui/VButton.vue";
import IconGeoMarker from "@/components/icons/IconGeoMarker.vue";
import IconWatch from "@/components/icons/IconWatch.vue";
import IconPhone from "@/components/icons/IconPhone.vue";
import IconWeb from "@/components/icons/IconWeb.vue";
import VStars from "@/components/ui/VStars.vue";
import IconEmptyStar from "@/components/icons/IconEmptyStar.vue";
import IconPen from "@/components/icons/IconPen.vue";
import Review from "@/components/pages/reviews/Review.vue";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";

import router from "@/router";

import { API_WITHOUT_GUARDS } from "@/assets/js/api/apiWithoutGuards";
import "swiper/css/bundle";

import {
  CUISINES,
  FEATURES,
  RESTAURANT_TYPES,
  WEEKDAYS,
  WEEKDAYS_RUSSIAN,
} from "@/assets/js/utils/consts";
import Logo from "@/assets/images/logo.png";
import VImage from "@/components/ui/VImage.vue";
import { prodCheck } from "@/assets/js/utils/prodCheck";
import { BASE_URL } from "@/assets/js/api/baseUrl";
import IconEmptyFavorite from "@/components/icons/IconEmptyFavorite.vue";
import { API } from "@/assets/js/api/api";
import IconItemArrow from "@/components/icons/IconItemArrow.vue";
import Swiper, { Navigation } from "swiper";
import store from "@/store";
import IconArrowBack from "@/components/icons/IconArrowBack.vue";
import SwiperModal from "@/components/modals/SwiperModal.vue";
import AuthLogin from "@/components/modals/auth/AuthLoginModal.vue";
import { convertFromNumberToTime, formatLink } from "@/assets/js/utils/helpers";
import IconSelectArrow from "@/components/icons/IconSelectArrow.vue";

export default {
  name: "BigObjectItem",
  components: {
    IconSelectArrow,
    IconArrowBack,
    IconItemArrow,
    IconEmptyFavorite,
    VImage,
    IconPen,
    IconEmptyStar,
    IconPhone,
    IconWatch,
    IconGeoMarker,
    VButton,
    IconWeb,
    VStars,
    PerfectScrollbar,
    Review,
  },

  props: {
    restaurants: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      details: {},
      btnHovered: false,
      images: [],
      logo: "",
      defaultLogo: Logo,
      photos: [],
      swiper: null,
      isSchedule: false,
      isLoaded: false,
    };
  },

  methods: {
    formatLink,
    updatedWeekdays(val) {
      if (val) {
        const arr = [];
        const weekdays = Object.keys(WEEKDAYS_RUSSIAN);
        const plainVal = [];
        for (let i = 0; i < val.length; i++) {
          for (const key in val[i].weekDays) {
            plainVal.push({ ...val[i], weekDays: [val[i].weekDays[key]] });
          }
        }
        weekdays.forEach((el) => {
          const weekDay = plainVal.find((day) => day.weekDays[0] === el);
          if (weekDay) {
            arr.push(weekDay);
          } else {
            arr.push({ weekDays: [el], time: null });
          }
        });
        return arr;
      }
      return [];
    },
    getTime(val) {
      return convertFromNumberToTime(val).join(":");
    },
    getRussianWeekday(value) {
      return WEEKDAYS_RUSSIAN[value];
    },
    onArrowClickHandler() {
      this.$router.push("/");
    },
    openModal(images, index, isComment) {
      this.$store.dispatch("openModal", {
        value: {
          component: SwiperModal,
          props: {
            images: {
              urls: isComment ? images : images.map((el) => el.url),
              initIndex: index,
            },
          },
        },
      });
    },
    book() {
      this.$router.push(`/booking/${this.clickedCatering}`);
    },
    getType(id) {
      return RESTAURANT_TYPES[id];
    },
    getCuisines(value) {
      return CUISINES[value];
    },
    getFeature(value) {
      return FEATURES[value];
    },
    clickOutside() {
      this.$router.push("/");
    },
    formatTime(timeString) {
      const hour = timeString.slice(0, 2);
      const minute = timeString.slice(2);
      return `${hour}:${minute}`;
    },
    isPlaceOpen(workingHours, time) {
      if (!workingHours) {
        return [];
      }

      const openingTime = this.formatTime(workingHours[0]);
      const closingTime = this.formatTime(workingHours[1]);

      const currentTime = new Date(`2000-01-01T${time}`);
      const opening = new Date(`2000-01-01T${openingTime}`);
      const closing = new Date(`2000-01-01T${closingTime}`);

      if (+workingHours[1] < 600) {
        closing.setDate(2);
      }

      return [
        currentTime >= opening && currentTime <= closing,
        `${openingTime} - ${closingTime}`,
      ];
    },

    addReview() {
      if (!this.$store.state.general.userInfo.id) {
        router.push("/");
        this.$store.dispatch("openModal", {
          value: {
            component: AuthLogin,
            props: null,
          },
        });
        store.commit({
          type: "setClickedCluster",
          value: null,
        });
      } else {
        this.$store.commit({
          type: "setMapEditMode",
          value: "reviewForm",
        });
      }
    },
    addReviews() {
      this.$store.dispatch("addCurrentItems");
    },
    async favoriteToggle() {
      try {
        await API.business.catering.addFavorite({
          catId: this.clickedCatering,
        });

        let favCats = this.$store.state.restaurants.favRestaurants;

        if (this.isFav) {
          favCats = this.$store.state.restaurants.favRestaurants.filter(
            (el) => el.catId !== this.clickedCatering
          );
        } else {
          favCats.push({ catId: this.clickedCatering });
        }

        this.$store.commit({
          type: "setFavRestaurants",
          value: favCats,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },

  computed: {
    screenWidth() {
      return this.$store.getters.screenType;
    },
    clickedCatering() {
      return this.$route.params.catId;
    },
    isDisabled() {
      if (this.details?.scheme && this.details?.available) {
        return !(!this.isAuth || this.role === "CLIENT");
      } else {
        return true;
      }
    },
    isFav() {
      return !!this.$store.state.restaurants.favRestaurants.find(
        (el) => el.catId === this.clickedCatering
      );
    },
    reviewButtonDisabled() {
      return this.roleGroup === "org" || this.roleGroup === "biz";
    },
    roleGroup() {
      return this.$store.getters.roleGroup;
    },
    role() {
      return this.$store.getters.role;
    },
    isAuth() {
      return this.$store.state.general.auth.isAuth;
    },
    userId() {
      return this.$store.state.general.userInfo.id;
    },

    page() {
      return this.$store.state.reviews.page;
    },
    pagesCount() {
      return this.$store.state.reviews.pagesCount;
    },
    currentReviews() {
      return this.$store.state.reviews.currentItems;
    },
    reviews() {
      return this.$store.state.reviews.items;
    },
    stars() {
      return this.reviews.length
        ? (
            this.reviews.map((el) => el.star).reduce((a, b) => a + b) /
            this.reviews.length
          ).toFixed(1)
        : 0;
    },
    isOpen() {
      if (this.details.workingTime) {
        const date = new Date();
        const currentTime = `${
          date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
        }:${
          date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
        }`;
        const currentWeekday = WEEKDAYS[date.getDay()];

        const targetWeekday = this.details.workingTime.find((el) =>
          el.weekDays.includes(currentWeekday)
        );

        if (!targetWeekday) {
          return [false];
        }

        return this.isPlaceOpen(targetWeekday?.time, currentTime);
      } else {
        return [false];
      }
    },
  },

  watch: {
    async clickedCatering(value) {
      if (value) {
        this.$store.commit({ type: "resetCateringReview" });
        const res = await API_WITHOUT_GUARDS.client.getCateringByID(
          this.clickedCatering
        );
        if (value) {
          this.details = res.data;
          this.$store.dispatch("loadCateringItems", {
            value: this.details.id,
          });

          if (this.screenWidth !== "mobile") {
            setTimeout(() => {
              const el = document.getElementsByClassName("ps__rail-y")[0];
              el.addEventListener("click", (event) => {
                event.stopPropagation();
              });
              el.addEventListener("mousemove", (event) => {
                event.stopPropagation();
              });
              el.addEventListener("dbclick", (event) => {
                event.stopPropagation();
              });
              el.addEventListener("wheel", (event) => {
                event.stopPropagation();
              });
            });
          }
        }
      }
    },
  },

  async mounted() {
    this.url = prodCheck() ? window.location.origin + "/api/v1/" : BASE_URL;

    this.$store.commit({ type: "resetCateringReview" });
    window.scrollTo(0, 0);

    try {
      const res = await API_WITHOUT_GUARDS.client.getCateringByID(
        this.clickedCatering
      );
      this.details = res.data;
      this.isLoaded = true;
      this.$store.dispatch("loadCateringItemsMap", { value: this.details.id });

      try {
        const images = await API_WITHOUT_GUARDS.catering.getImagesByEviso(
          this.details.id
        );
        this.photos = images.data.photos || [];
        this.logo = images.data.logo?.url;
      } catch (e) {
        console.log(e);
      }

      if (this.screenWidth !== "mobile") {
        const el = document.getElementsByClassName("ps__rail-y")[0];
        el.addEventListener("click", (event) => {
          event.stopPropagation();
        });
        el.addEventListener("mousemove", (event) => {
          event.stopPropagation();
        });
        el.addEventListener("dbclick", (event) => {
          event.stopPropagation();
        });
        el.addEventListener("wheel", (event) => {
          event.stopPropagation();
        });
      }

      this.$nextTick(() => {
        this.swiper = new Swiper(this.$refs.swiper, {
          modules: [Navigation],
          lazy: true,
          loop: true,
          navigation: {
            nextEl: this.$refs.next,
            prevEl: this.$refs.prev,
          },
        });
      });
    } catch (e) {
      this.$router.push("/");
    }
  },

  beforeUnmount() {
    this.$store.commit({ type: "setReviews", value: [] });
  },
};
</script>

<style scoped lang="scss">
.svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
}

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000000;
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100%;
  background-color: #ffffff;
  border-left: 1px solid #d6d6d6;
  cursor: auto;
  overflow-y: auto;

  &._restaurants {
    position: relative;
    top: auto;
    left: auto;
  }

  @include mobile {
    width: 100%;
  }
}

.check {
  padding: 24px 16px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 1px solid #d6d6d6;

  &-bold {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }
}

.reviews {
  padding: 24px 0 8px 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 1px solid #d6d6d6;

  &-header {
    padding: 0 24px;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;

    span {
      color: #f18e35;
    }
  }

  .review {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .more {
    padding: 0 16px 24px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #f18e35;
    cursor: pointer;
  }
}

.empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #858585;

  svg {
    margin-bottom: 18px;
  }

  .question {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
  }

  .action {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}

.features {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 1px solid #d6d6d6;

  &-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  }

  &-blocks {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;

    div {
      margin-right: 10px;
      padding: 4px 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      border: 1px solid #f18e35;
      border-radius: 8px;
    }
  }
}

.kitchens {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 1px solid #d6d6d6;

  &-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  }

  &-blocks {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;

    div {
      margin-right: 10px;
      padding: 4px 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      border: 1px solid #f18e35;
      border-radius: 8px;
    }
  }
}

.main-info {
  display: flex;
  align-items: flex-start;
  padding: 24px 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #222222;
  border-bottom: 1px solid #d6d6d6;

  &-price {
    margin-left: auto;
  }

  &-rating {
    display: flex;
    align-items: center;
    margin-top: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #858585;

    &-number {
      margin-right: 10px;
    }

    svg:not(:last-child) {
      margin-right: 6px;
    }
  }

  &-title {
    overflow-wrap: break-word;

    &-name {
      font-family: $font-family-secondary;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }

    &-type {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &-default-logo {
    flex: 0 0 auto;
    width: 62px;
    height: 62px;
    margin-right: 16px;
    border-radius: 50%;
    background-color: #ebebeb;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    filter: grayscale(1);

    @include mobile {
      width: 54px;
      height: 54px;
    }
  }

  &-logo {
    flex: 0 0 auto;
    width: 62px;
    height: 62px;
    margin-right: 16px;
    border-radius: 50%;

    @include mobile {
      width: 54px;
      height: 54px;
    }
  }
}

.details {
  padding: 26px 18px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #222222;
  border-bottom: 1px solid #d6d6d6;

  & > div:not(:last-child) {
    margin-bottom: 18px;
  }

  svg {
    margin-right: 6px;
  }

  &-tableu {
    color: #f18e35;
    cursor: pointer;

    &-text {
      margin-right: 8px;
    }

    &-open {
      margin-right: 16px;
    }
  }

  &-address,
  &-time,
  &-phone,
  &-link {
    display: flex;
    align-items: center;
  }

  &-working-hours {
    display: flex;
    flex-direction: column;
    align-items: initial;
    height: 108px;
    flex-wrap: wrap;
    row-gap: 4px;
    margin-top: -10px;

    font-size: 16px;
    font-weight: 400;
    text-align: left;

    &-row {
      display: flex;
    }

    &-label {
      margin-right: 4px;
      font-weight: 600;
    }

    &-weekend {
      color: #f18e35;
    }
  }
}

.swiper {
  width: 400px;
  height: 220px;
  overflow: initial;

  @include mobile {
    width: 100%;
  }

  .default-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 220px;
    filter: grayscale(1);
    background: #ebebeb;

    @include mobile {
      width: 100%;
    }

    img {
      width: 100px;
      height: 100px;
    }
  }

  .image {
    height: 220px;
    cursor: pointer;
  }
}

.btns {
  padding: 24px 16px;
}

.btn {
  padding: 12px 20px;
  width: 368px;
  height: 52px;
  border-radius: 12px;

  @include mobile {
    width: 100%;
  }

  &:first-child {
    margin-bottom: 16px;
  }
}

.stars {
  margin-top: 16px;
}

.favorite {
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1;
  cursor: pointer;
}

.swiper {
  height: 100%;
}

.button-next {
  position: absolute;
  top: 50%;
  right: 8px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 15;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(34, 34, 34, 0.5);
  transform: translateY(-50%) rotate(180deg);
}

.button-prev {
  position: absolute;
  top: 50%;
  left: 8px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 15;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(34, 34, 34, 0.5);
  transform: translateY(-50%);
}

.swiper-button-lock {
  display: none;
}

.mySwiper {
  overflow: hidden;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 56px;
  flex: 0 0 auto;
  padding: 16px;

  svg {
    cursor: pointer;
  }
}

.swiper {
  position: relative;
  z-index: 0;
}
</style>
