<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Details">
      <g id="Details_2">
        <path
          d="M26.78 19.0759C27.2947 19.5924 27.2947 20.4297 26.78 20.9462C26.2653 21.4626 25.4307 21.4626 24.916 20.9462L15.5961 11.5949C15.0814 11.0784 15.0814 10.241 15.5961 9.72459C16.1108 9.20813 16.9454 9.20813 17.4601 9.72459L26.78 19.0759Z"
          :fill="color"
        />
        <path
          d="M8.0827 20.9424C7.56798 21.4589 6.73345 21.4589 6.21873 20.9424C5.704 20.4259 5.704 19.5886 6.21873 19.0721L15.5386 9.72084C16.0533 9.20438 16.8879 9.20438 17.4026 9.72084C17.9173 10.2373 17.9173 11.0746 17.4026 11.5911L8.0827 20.9424Z"
          :fill="color"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconBookingSelectArrow",
  props: {
    color: {
      type: String,
      default: "#F18E35",
    },
  },
};
</script>

<style scoped></style>
