<template>
  <svg
    data-v-0872320e=""
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.27844 6.62274C4.90722 6.25151 4.90722 5.64964 5.27844 5.27842C5.64966 4.9072 6.25153 4.9072 6.62275 5.27842L18.7216 17.3773C19.0928 17.7485 19.0928 18.3504 18.7216 18.7216C18.3504 19.0928 17.7485 19.0928 17.3773 18.7216L5.27844 6.62274Z"
      fill="#222222"
    ></path>
    <path
      d="M17.3772 5.27842C17.7485 4.90719 18.3503 4.90719 18.7216 5.27842C19.0928 5.64964 19.0928 6.25151 18.7216 6.62273L6.62273 18.7216C6.25151 19.0928 5.64964 19.0928 5.27842 18.7216C4.90719 18.3504 4.90719 17.7485 5.27842 17.3773L17.3772 5.27842Z"
      fill="#222222"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "IconWidgetCloseCross",
};
</script>

<style scoped></style>
