<template>
  <nav ref="nav">
    <template v-if="role === 'ROOT'">
      <router-link to="/account/administrators">
        <NavButton icon="administrators">Учетные записи</NavButton>
      </router-link>
      <router-link to="/account/settings">
        <NavButton icon="settings">Настройки</NavButton>
      </router-link>
    </template>
    <template v-else-if="role === 'ADMIN'">
      <router-link to="/account/catering">
        <NavButton icon="catering">Заведения</NavButton>
      </router-link>
      <div v-if="isCatering" class="tabs">
        <span
          @click="$router.push('/account/catering')"
          :class="{ _active: $route.name === 'catering' }"
        >
          Заведения
        </span>

        <span
          @click="$router.push('/account/catering/moderator-reviews')"
          :class="{ _active: $route.name === 'moderator-reviews' }"
        >
          Отзывы
        </span>
      </div>
      <router-link to="/account/statistics">
        <NavButton icon="statistics">Статистика</NavButton>
      </router-link>
      <div v-if="isStatistics" class="tabs">
        <span
          @click="onStatisticsTabClickHandler(0)"
          :class="{ _active: statisticsTab === 0 }"
        >
          Менеджеры
        </span>
        <span
          @click="onStatisticsTabClickHandler(1)"
          :class="{ _active: statisticsTab === 1 }"
        >
          Команды
        </span>

        <span
          @click="onStatisticsTabClickHandler(2)"
          :class="{ _active: statisticsTab === 2 }"
        >
          Модераторы
        </span>
      </div>
      <router-link to="/account/counterparties">
        <NavButton icon="counterparties">Контрагенты</NavButton>
      </router-link>
      <div v-if="isCounterparty" class="tabs">
        <span
          @click="$router.push('/account/counterparties/jur')"
          :class="{ _active: $route.name === 'counterparties/jur' }"
        >
          Юр. лица
        </span>
        <span
          @click="$router.push('/account/counterparties/ip')"
          :class="{ _active: $route.name === 'counterparties/ip' }"
        >
          ИП
        </span>
      </div>
      <router-link to="/account/clients">
        <NavButton icon="clients">Клиенты</NavButton>
      </router-link>
      <div v-if="isClient" class="tabs">
        <span
          @click="$router.push('/account/clients/b2b')"
          :class="{ _active: $route.name === 'clients/b2b' }"
        >
          Клиенты B2B
        </span>
        <span
          @click="$router.push('/account/clients/b2c')"
          :class="{ _active: $route.name === 'clients/b2c' }"
        >
          Клиенты B2C
        </span>
      </div>
      <router-link to="/account/tariffs">
        <NavButton icon="tariffs">Тарифы</NavButton>
      </router-link>
      <router-link to="/account/settings">
        <NavButton icon="settings">Настройки</NavButton>
      </router-link>
      <div v-if="isAccount" class="tabs">
        <span
          @click="$router.push('/account/settings')"
          :class="{ _active: $route.name === 'settings' }"
        >
          Аккаунт
        </span>
        <span
          @click="$router.push('/account/settings/managers')"
          :class="{ _active: $route.name === 'settings-managers' }"
        >
          Управление менеджерами
        </span>
        <span
          v-if="role === 'ADMIN'"
          @click="$router.push('/account/settings/moderators')"
          :class="{ _active: $route.name === 'settings-moderators' }"
        >
          Управление модераторами
        </span>
        <span
          v-if="role === 'ADMIN'"
          @click="$router.push('/account/settings/secretaries')"
          :class="{ _active: $route.name === 'settings-secretaries' }"
        >
          Управление секретарями
        </span>
      </div>
    </template>
    <template v-else-if="role === 'HEAD' || role === 'MANAGER'">
      <router-link to="/account/catering">
        <NavButton icon="catering">Заведения</NavButton>
      </router-link>
      <router-link to="/account/statistics">
        <NavButton icon="statistics">Статистика</NavButton>
      </router-link>
      <div v-if="isStatistics && role === 'HEAD'" class="tabs">
        <span
          @click="onStatisticsTabClickHandler(0)"
          :class="{ _active: statisticsTab === 0 }"
        >
          Моя статистика
        </span>
        <span
          @click="onStatisticsTabClickHandler(1)"
          :class="{ _active: statisticsTab === 1 }"
        >
          Менеджеры
        </span>

        <span
          @click="onStatisticsTabClickHandler(2)"
          :class="{ _active: statisticsTab === 2 }"
        >
          Команда
        </span>
      </div>
      <router-link to="/account/counterparties">
        <NavButton icon="counterparties">Контрагенты</NavButton>
      </router-link>
      <div v-if="isCounterparty" class="tabs">
        <span
          @click="$router.push('/account/counterparties/jur')"
          :class="{ _active: $route.name === 'counterparties/jur' }"
        >
          Юр. лица
        </span>
        <span
          @click="$router.push('/account/counterparties/ip')"
          :class="{ _active: $route.name === 'counterparties/ip' }"
        >
          ИП
        </span>
      </div>
      <router-link to="/account/clients">
        <NavButton icon="clients">Клиенты</NavButton>
      </router-link>
      <router-link to="/account/settings">
        <NavButton icon="settings">Настройки</NavButton>
      </router-link>
      <div v-if="isAccount" class="tabs">
        <span
          v-if="role === 'HEAD'"
          @click="$router.push('/account/settings')"
          :class="{ _active: $route.name === 'settings' }"
        >
          Аккаунт
        </span>
        <span
          v-if="role === 'HEAD'"
          @click="$router.push('/account/settings/managers')"
          :class="{ _active: $route.name === 'settings-managers' }"
        >
          Управление менеджерами
        </span>
      </div>
    </template>
    <template v-else-if="role === 'MODERATOR'">
      <router-link to="/account/reviews">
        <NavButton icon="reviews">Отзывы</NavButton>
      </router-link>
      <router-link to="/account/statistics">
        <NavButton icon="statistics">Статистика</NavButton>
      </router-link>
      <router-link to="/account/settings">
        <NavButton icon="settings">Настройки</NavButton>
      </router-link>
    </template>
    <template v-else-if="role === 'SECRETARY'">
      <router-link to="/account/documents">
        <NavButton icon="documents">Документы</NavButton>
      </router-link>
      <router-link to="/account/settings">
        <NavButton icon="settings">Настройки</NavButton>
      </router-link>
    </template>
    <template v-else-if="role === 'MAJOR'">
      <router-link to="/account/catering">
        <NavButton icon="catering" path="catering">Заведения</NavButton>
      </router-link>
      <div v-if="isCatering && role === 'MAJOR'" class="tabs">
        <span
          @click="$router.push('/account/catering')"
          :class="{ _active: $route.name === 'catering' }"
        >
          Мои заведения
        </span>

        <span
          @click="$router.push('/account/catering/business-booking')"
          :class="{ _active: $route.name === 'catering/business-booking' }"
        >
          Бронирования
        </span>

        <!--        <span-->
        <!--          @click="$router.push('/account/sms')"-->
        <!--          :class="{ _active: $route.name === 'sms' }"-->
        <!--        >-->
        <!--          SMS-уведомления-->
        <!--        </span>-->

        <span
          @click="$router.push('/account/catering/reviews')"
          :class="{ _active: $route.name === 'biz-reviews' }"
        >
          Отзывы
        </span>
      </div>
      <router-link to="/account/statistics">
        <NavButton icon="statistics" path="statistics">Статистика</NavButton>
      </router-link>
      <router-link :to="`/account/clients/${documentsB2BLinkID}`">
        <NavButton icon="documents" path="clients">Документы</NavButton>
      </router-link>
      <router-link to="/account/employees">
        <NavButton icon="employees" path="employees">Сотрудники</NavButton>
      </router-link>
      <router-link to="/account/payments">
        <NavButton icon="payments">Тарифы</NavButton>
      </router-link>
      <router-link to="/account/widgets">
        <NavButton icon="widgets">Виджет</NavButton>
      </router-link>
      <router-link to="/account/settings">
        <NavButton icon="settings" path="settings">Настройки</NavButton>
      </router-link>
    </template>
    <template v-else-if="role === 'HOSTESS'">
      <router-link to="/account/business-booking">
        <NavButton icon="business-booking">Бронирования</NavButton>
      </router-link>
      <router-link to="/account/settings">
        <NavButton icon="settings">Настройки</NavButton>
      </router-link>
    </template>
    <template v-else-if="role === 'MARKETER'">
      <router-link to="/account/catering">
        <NavButton icon="catering">Заведения</NavButton>
      </router-link>
      <div v-if="isCatering" class="tabs">
        <span
          @click="$router.push('/account/catering')"
          :class="{ _active: $route.name === 'catering' }"
        >
          Мои заведения
        </span>

        <span
          @click="$router.push('/account/catering/business-booking')"
          :class="{ _active: $route.name === 'catering/business-booking' }"
        >
          Бронирования
        </span>
        <span
          @click="$router.push('/account/catering/reviews')"
          :class="{ _active: $route.name === 'biz-reviews' }"
        >
          Отзывы
        </span>
      </div>
      <router-link to="/account/statistics">
        <NavButton icon="statistics">Статистика</NavButton>
      </router-link>
      <router-link :to="`/account/clients/${documentsB2BLinkID}`">
        <NavButton icon="documents" path="clients">Документы</NavButton>
      </router-link>
      <router-link to="/account/employees">
        <NavButton icon="employees">Сотрудники</NavButton>
      </router-link>
      <router-link to="/account/settings">
        <NavButton icon="settings">Настройки</NavButton>
      </router-link>
    </template>
    <template v-else>
      <router-link to="/account/settings">
        <NavButton icon="settings">Личный кабинет</NavButton>
      </router-link>
      <router-link to="/account/favorites">
        <NavButton icon="favorites">Избранное</NavButton>
      </router-link>
      <router-link to="/account/bookings">
        <NavButton icon="bookings">Бронирования</NavButton>
      </router-link>
      <router-link to="/account/reviews">
        <NavButton icon="reviews">Отзывы</NavButton>
      </router-link>
    </template>
    <a
      v-if="roleGroup === 'client' && $route.name === 'settings'"
      class="support"
      href="https://jivo.chat/2EfX44qOaO"
      target="_blank"
    >
      <div class="button">
        <IconGreyPhone />
        <span>Позвонить</span>
      </div>
    </a>
    <a
      v-if="roleGroup === 'biz'"
      class="support"
      href="https://jivo.chat/EjNEFglFfX"
      target="_blank"
    >
      <div class="button">
        <IconGreyPhone />
        <span>Позвонить</span>
      </div>
    </a>
  </nav>
</template>

<script setup>
import { ref, watch } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import IconGreyPhone from "@/components/icons/IconGreyPhone.vue";

const store = useStore();
const route = useRoute();
const nav = ref(null);

onClickOutside(nav, (event) => {
  if (
    event.target.className !== "line line3" &&
    event.target.className !== "line line2" &&
    event.target.className !== "line line1" &&
    event.target.className !== "hamburger-lines"
  ) {
    store.commit({
      type: "toggleMenu",
    });
  }
});

watch(
  () => route.path,
  () => {
    if (store.state.general.isMenu) {
      store.commit({
        type: "toggleMenu",
      });
    }
  }
);
</script>

<script>
import NavButton from "@/components/ui/NavButton";

export default {
  name: "Nav",
  components: {
    NavButton,
  },
  computed: {
    role() {
      return this.$store.getters.role;
    },
    roleGroup() {
      return this.$store.getters.roleGroup;
    },
    statisticsTab() {
      return this.$store.state.statistics?.tab;
    },
    isAccount() {
      return this.$route.path.includes("settings");
    },
    isClient() {
      return (
        this.$route.name === "clients" ||
        this.$route.name === "clients/b2b" ||
        this.$route.name === "clients/b2c" ||
        this.$route.name === "clients/b2c"
      );
    },
    isCounterparty() {
      return (
        this.$route.name === "counterparties" ||
        this.$route.name === "counterparties/jur" ||
        this.$route.name === "counterparties/ip" ||
        this.$route.name === "counterparties/form" ||
        this.$route.name === "counterparties/form/:id"
      );
    },
    isStatistics() {
      return this.$route.name === "statistics";
    },
    isCatering() {
      return (
        this.$route.name === "catering" ||
        this.$route.name === "biz-reviews" ||
        this.$route.name === "business-reviews" ||
        this.$route.name === "moderator-reviews" ||
        this.$route.name === "catering/business-booking" ||
        this.$route.name === "sms"
      );
    },
    documentsB2BLinkID() {
      if (this.$store.getters.role === "MARKETER") {
        return this.userInfo.owner;
      } else {
        return this.userInfo.id;
      }
    },
    userInfo() {
      return this.$store.state.general.userInfo;
    },
  },
  methods: {
    onStatisticsTabClickHandler(value) {
      this.$store.commit({
        type: "statisticsTab",
        value: value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  height: 100%;
  background-color: #fff;
  width: 232px;

  @include tablet {
    position: absolute;
    top: 0;
    left: 0;
    width: 228px;
    z-index: 9999;
  }

  button {
    margin-bottom: 12px;
  }
}

.tabs {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  padding: 8px 0 0 16px;
  margin-bottom: 12px;
  user-select: none;

  span {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #222222;
    cursor: pointer;

    &._active {
      font-weight: 600;
      line-height: 26px;
    }
  }

  span:last-child {
    margin-bottom: 0;
  }
}

.support {
  margin-top: auto;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 100px;
  background-color: #f6f6f6;

  span {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    color: #858585;
  }
}
</style>
