<template>
  <div class="tooltip" ref="tooltip">
    <div class="row">
      <span> Ваш ID: {{ prettyID }}</span>
    </div>
    <div class="row" @click="account">Личный кабинет</div>
    <div v-if="isJournal" @click="journal" class="row">Журнал</div>
    <AcceptModalButton
      ref="modal"
      acceptButtonText="Выйти"
      cancelButtonText="Отменить"
      title="Выйти из аккаунта?"
      text="Вы уверены, что хотите выйти?"
      @accept="logout"
      v-slot="scope"
    >
      <div @click.stop="scope.open" class="row">Выход</div>
    </AcceptModalButton>
  </div>
</template>

<script setup>
import { ref, defineEmits, computed } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import AcceptModalButton from "@/components/modals/AcceptModalButton.vue";

const store = useStore();
const router = useRouter();

const emit = defineEmits(["close"]);
const tooltip = ref(null);
const modal = ref(null);

const isJournal = computed(() => {
  return store.getters.role === "ADMIN" || store.getters.role === "ROOT";
});

const prettyID = computed(() => {
  return (
    store.state.general.userInfo?.prettyID?.toString().replace(/\D/g, "") ||
    store.state.general.userInfo?.prettyId?.toString().replace(/\D/g, "") ||
    ""
  );
});

const account = () => {
  emit("close");
  router.push("/account/settings");
};
const journal = () => {
  emit("close");
  router.push("/account/journal");
};
const logout = () => {
  emit("close");
  store.dispatch("logout");
};

onClickOutside(tooltip, () => {
  if (!modal.value.isOpen) {
    emit("close");
  }
});
</script>

<style scoped lang="scss">
.tooltip {
  position: absolute;
  right: 0;
  top: 64px;
  display: flex;
  flex-direction: column;
  width: 215px;
  background: #ffffff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  z-index: 10000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #222222;
  overflow: hidden;
}

.row {
  padding: 8px 12px 8px 20px;
  display: flex;
  cursor: pointer;

  &:not(:first-child):hover {
    background: #ebebeb;
  }

  &:first-child {
    padding: 12px 12px 8px 20px;
    cursor: auto;
    span {
      width: 100%;
      padding: 2px 8px;
      background: #f6f6f6;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #858585;
    }
  }

  &:last-child {
    color: #f03738;
  }
}
</style>
