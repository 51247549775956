<template>
  <div ref="block" class="review">
    <div class="review-author">
      <VImage v-if="avatar" :src="avatar.url" class="review-author-avatar" />
      <img
        v-else
        class="review-author-avatar"
        src="@/assets/images/defaultAvatar.png"
      />
      <span class="review-author-name">{{ review.authorName }}</span>
      <div class="icons" v-if="isClientList">
        <StatusIcon
          class="review-status-icon"
          v-if="review.statusID === 1"
          color="yellow"
        >
          На рассмотрении
        </StatusIcon>
        <StatusIcon
          class="review-status-icon"
          v-if="review.statusID === 2"
          color="green"
        >
          Опубликовано
        </StatusIcon>
        <StatusIcon
          class="review-status-icon"
          v-if="review.statusID === 3"
          color="red"
        >
          Отклонен
        </StatusIcon>
        <AcceptModalButton
          v-if="review.authorID === userID"
          acceptButtonText="Удалить"
          cancelButtonText="Отмена"
          title="Удалить отзыв?"
          text="Вы уверены, что хотите удалить свой отзыв о заведении? Восстановить его будет невозможно."
          @accept="deleteReview"
          v-slot="scope"
        >
          <IconDeleteCan @click="scope.open" class="review-delete-icon" />
        </AcceptModalButton>
      </div>
    </div>
    <div class="stars">
      <VStars class="review-rating" :value="review.star" />
      <span class="date">{{ getDate(review.createTime) }}</span>
    </div>
    <p v-if="isClientList || major" class="review-catName">
      {{ review.cateringName }}
    </p>
    <p class="review-description">{{ review.text }}</p>
    <div v-if="images.length" class="review-images">
      <VImage
        class="review-image"
        v-for="(image, index) in images"
        :src="image.url"
        :key="image"
        @click="openModal(images, index)"
      />
    </div>
    <div class="review-icons">
      <IconFavFilled
        class="like"
        :color="review.isLiked ? '#F18E35' : '#ADADAD'"
        :style="{ cursor: userID ? 'pointer' : 'auto' }"
        @click="likeToggle"
      />
      <span class="like-count">{{ review.likes }}</span>
      <template v-if="review.comments">
        <CommentIcon class="comment-icon" />
        <span>{{ review.comments.length }}</span>
      </template>
      <span
        v-if="isResponse && review.statusID !== 3 && review.statusID !== 1"
        class="response"
        @click="onEditClick"
        >Ответить</span
      >
    </div>
    <div
      v-if="review.moderatorComment && isClientList"
      class="review-comment"
      :style="{
        backgroundColor: review.statusID === 3 ? '#FDEBEB' : '#FEF6E8',
      }"
    >
      <div class="review-author _comment">
        <div class="review-author-avatar">
          <img src="@/assets/images/logo.png" alt="" />
        </div>
        <div class="moderator-name">
          <span>Администрация Eviso</span>
          <span class="date">{{ getDate(review.createTime) }}</span>
        </div>
      </div>
      <p>{{ review.moderatorComment }}</p>
    </div>
    <template v-if="comments.length">
      <div
        class="show-comment"
        v-if="comments.length > 1"
        @click="hide = !hide"
      >
        <span>{{ hide ? "Открыть комментарии" : "Скрыть комментарии" }}</span>
      </div>
      <template v-for="comment in shownComments" :key="comment.Id">
        <div
          class="review-comment"
          :class="{ _map: !isClientList && !major }"
          :style="{
            backgroundColor: comment.authorRole !== 4 ? '#FEF6E8' : 'none',
          }"
        >
          <div v-if="comment.authorRole === 4" class="review-author _comment">
            <div class="review-author-avatar">
              <VImage v-if="avatar" :src="avatar.url" class="logo" />
              <img
                v-else
                class="logo _default"
                src="@/assets/images/defaultAvatar.png"
              />
            </div>
            <span class="review-author-name">{{ review.authorName }}</span>
            <span class="date" :class="{ _comment: !major && !isClientList }">{{
              getDate(comment.createTime)
            }}</span>
            <div class="icons" v-if="major || isClientList">
              <StatusIcon
                class="review-status-icon"
                v-if="comment.statusID === 1 && isClientList"
                color="yellow"
              >
                На рассмотрении
              </StatusIcon>
              <StatusIcon
                class="review-status-icon"
                v-if="comment.statusID === 2 && isClientList"
                color="green"
              >
                Опубликовано
              </StatusIcon>
              <StatusIcon
                class="review-status-icon"
                v-if="comment.statusID === 3 && isClientList"
                color="red"
              >
                Отклонен
              </StatusIcon>
              <AcceptModalButton
                v-if="comment.authorID === userID"
                acceptButtonText="Удалить"
                cancelButtonText="Отмена"
                title="Удалить отзыв?"
                text="Вы уверены, что хотите удалить свой комментарий к отзыву? Восстановить его будет невозможно."
                @accept="deleteReviewComment(comment.Id, comment.authorID)"
                v-slot="scope"
              >
                <IconDeleteCan @click="scope.open" class="review-delete-icon" />
              </AcceptModalButton>
            </div>
          </div>
          <div v-else class="review-author _comment">
            <div class="review-author-avatar">
              <VImage v-if="catLogo" :src="catLogo" class="logo" />
              <VImage v-else :src="catDefaultLogo" class="logo _default" />
            </div>
            <span class="review-author-name">{{ comment.cateringName }}</span>
            <span class="date" :class="{ _comment: !major && !isClientList }">{{
              getDate(comment.createTime)
            }}</span>
            <div class="icons" v-if="major">
              <StatusIcon
                class="review-status-icon"
                v-if="comment.statusID === 1"
                color="yellow"
              >
                На рассмотрении
              </StatusIcon>
              <StatusIcon
                class="review-status-icon"
                v-if="comment.statusID === 2"
                color="green"
              >
                Опубликовано
              </StatusIcon>
              <StatusIcon
                class="review-status-icon"
                v-if="comment.statusID === 3"
                color="red"
              >
                Отклонен
              </StatusIcon>
              <AcceptModalButton
                v-if="comment.authorID === userID"
                acceptButtonText="Удалить"
                cancelButtonText="Отмена"
                title="Удалить отзыв?"
                text="Вы уверены, что хотите удалить свой комментарий к отзыву? Восстановить его будет невозможно."
                @accept="deleteReviewComment(comment.Id, comment.authorID)"
                v-slot="scope"
              >
                <IconDeleteCan @click="scope.open" class="review-delete-icon" />
              </AcceptModalButton>
            </div>
          </div>
          <p class="comment-text">{{ comment.text }}</p>
          <div v-if="comment.images?.length" class="review-images _major">
            <VImage
              class="review-image"
              v-for="(image, index) in comment.images"
              :src="image"
              :key="image"
              @click="openModal(comment.images, index, true)"
            />
          </div>
          <div class="review-icons">
            <IconFavFilled
              class="like"
              :color="comment.isLiked ? '#F18E35' : '#ADADAD'"
              :style="{ cursor: userID ? 'pointer' : 'auto' }"
              @click="likeToggleComment(comment.Id)"
            />
            <span>{{ comment.likes }}</span>
          </div>
        </div>
        <div
          v-if="comment.moderatorComment && (major || isClientList)"
          class="review-comment"
          :style="{
            backgroundColor: comment.statusID === 3 ? '#FDEBEB' : '#FEF6E8',
          }"
        >
          <div class="review-author _comment">
            <div class="review-author-avatar">
              <img src="@/assets/images/logo.png" alt="" />
            </div>
            <div class="moderator-name">
              <span>Администрация Eviso</span>
              <span class="date">{{ getDate(review.createTime) }}</span>
            </div>
          </div>
          <p>{{ comment.moderatorComment }}</p>
        </div>
      </template>
    </template>

    <div
      v-if="isEdit"
      class="review-comment"
      :class="{ _map: !isClientList && !major }"
    >
      <div class="review-author _comment">
        <template v-if="role === 'CLIENT'">
          <div class="review-author-avatar">
            <VImage v-if="avatar" :src="avatar.url" class="logo" />
            <img
              v-else
              class="logo _default"
              src="@/assets/images/defaultAvatar.png"
            />
          </div>
          <span>{{ review.authorName }}</span>
        </template>
        <template v-else>
          <div class="review-author-avatar">
            <VImage :src="catLogo" class="logo" />
          </div>
          <span>{{ review.cateringName }}</span>
        </template>
      </div>
      <div class="text">
        <VTextArea v-model:value="description" class="textarea" is-clear />
        <span>Минимум 100 символов</span>
      </div>
      <div class="photos">
        <input
          :style="{ display: 'none' }"
          type="file"
          id="photos"
          @change="uploadImage($event, role === 'CLIENT')"
          accept="image/*,.heic,.heif"
        />
        <div class="container">
          <ImageLoadLabel
            v-if="photosStatus.isLoad && photos.length < 2"
            type="load"
            forId="photos"
            class="photos-img"
          />
          <ImageLoadLabel
            v-else-if="photosStatus.error && photos.length < 2"
            :type="photosStatus.error"
            forId="photos"
            class="photos-img"
          />
          <ImageLoadLabel
            v-else-if="photos.length < 3 && !isClientList && !major"
            type="add"
            forId="photos"
            class="photos-img"
          />
          <template v-for="photo in photos" :key="photo.fileID">
            <div v-if="photo.type === 'heic'" class="photos-img _heic">
              <IconInvalidType />
              <span>Для файлов heic предпросмотр недоступен</span>
            </div>
            <VImage
              v-else
              class="photos-img"
              :src="photo.url"
              is-deletable
              @image-deleted="deleteImage(photo.id)"
            />
          </template>
        </div>
        <label class="input-label" for="photos" v-if="isClientList || major">
          <div class="addBtn" v-if="photos.length < 3">
            <IconPlus color="#f18e35" />
            <span> Добавить фотографию </span>
          </div>
        </label>
        <p class="photos-alert">
          Загрузите до 3 фотографий JPG или PNG до 6 МБ каждая
        </p>

        <div class="btns">
          <template v-if="!isClientList && !major">
            <VButton class="btn" outline @click="isEdit = false">
              Закрыть
            </VButton>
            <VButton
              class="btn"
              @click="send"
              :disabled="description.length < 100"
            >
              Отправить
            </VButton>
          </template>
          <VButton
            v-else
            class="btn _send"
            @click="send"
            :disabled="description.length < 100"
          >
            Отправить
          </VButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconFavFilled from "@/components/icons/IconFavFilled";
import IconDeleteCan from "@/components/icons/IconDeleteCan";
import AcceptModalButton from "@/components/modals/AcceptModalButton";
import StatusIcon from "@/components/ui/StatusIcon";
import VStars from "@/components/ui/VStars";
import VImage from "@/components/ui/VImage";
import { API } from "@/assets/js/api/api";
import { API_WITHOUT_GUARDS } from "@/assets/js/api/apiWithoutGuards";
import VTextArea from "@/components/ui/VTextArea.vue";
import ImageLoadLabel from "@/components/ui/ImageLoadLabel.vue";
import VButton from "@/components/ui/VButton.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import CommentIcon from "@/components/icons/CommentIcon.vue";

import Logo from "@/assets/images/logo.png";
import { getDate } from "@/assets/js/utils/helpers";
import IconInvalidType from "@/components/icons/IconInvalidType.vue";
import SwiperModal from "@/components/modals/SwiperModal.vue";

export default {
  name: "VReview",
  components: {
    IconInvalidType,
    CommentIcon,
    IconPlus,
    VButton,
    ImageLoadLabel,
    VTextArea,
    StatusIcon,
    IconDeleteCan,
    IconFavFilled,
    AcceptModalButton,
    VStars,
    VImage,
  },
  props: {
    review: {
      type: Object,
      required: true,
    },
    isClientList: {
      type: Boolean,
      default: false,
    },
    major: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hide: true,
      images: {},
      avatar: "",
      isEdit: false,
      photos: [],
      photosStatus: {
        isLoad: false,
        error: "",
      },
      description: "",
      hovered: false,
      activeItem: null,
      editableComment: null,
      tempId: "",
      comments: [],
      catDefaultLogo: Logo,
      catLogo: "",
    };
  },
  methods: {
    openModal(images, index, isComment) {
      this.$store.dispatch("openModal", {
        value: {
          component: SwiperModal,
          props: {
            images: {
              urls: isComment ? images : images.map((el) => el.url),
              initIndex: index,
            },
          },
        },
      });
    },
    onEditClick() {
      if (!this.major || !this.isClientList) {
        if (this.review.authorID !== this.userID) {
          this.$emit("new-review");
        }
      }
      this.isEdit = true;
    },
    getDate(value) {
      return getDate(value);
    },
    clickOutside() {
      if (this.shownItem) {
        this.activeItem = null;
        this.shownItem = null;
      }
      this.shownItem = this.activeItem;
    },
    send() {
      try {
        this.$store.dispatch("createComment", {
          value: {
            authorID: this.$store.state.general.userInfo.id,
            authorName:
              this.$store.state.general.userInfo.name ||
              this.$store.state.general.userInfo.firstName,
            authorRole: this.role === "CLIENT" ? 4 : 1,
            cateringID: this.review.cateringID,
            parentID: this.review.Id,
            text: this.description,
            fileIDs: this.photos.map((el) => el.id),
            ID: this.tempId,
          },
        });
        this.isLoaded = false;
        this.isEdit = false;
        this.description = "";
      } catch (err) {
        console.log(err);
      }
    },
    async uploadImage(event, isClient) {
      const { files } = event.target;
      if (files && files[0]) {
        const blob = URL.createObjectURL(files[0]);
        const formData = new FormData();
        const isInvalidSize = files[0].size > 6291456;

        const ValidImageTypes = [
          "image/jpg",
          "image/jpeg",
          "image/png",
          "image/gif",
          "image/bmp",
        ];

        const isValidType = ValidImageTypes.includes(files[0].type);

        const isHeic = files[0].name.includes(".heic");

        formData.append("file", files[0]);
        formData.append("reviewid", this.Id || this.tempId);
        try {
          this.photosStatus.error = "";
          this.photosStatus.isLoad = true;

          if (!isValidType && !isHeic) {
            this.photosStatus.error = "invalid";
            this.photosStatus.isLoad = false;
            return;
          }

          if (isInvalidSize) {
            this.photosStatus.error = "invalid";
            this.photosStatus.isLoad = false;
            return;
          }

          const res = await API.reviews.uploadCommentImage(
            this.review.Id,
            this.tempId,
            formData,
            isClient
          );
          this.photos.push({
            url: blob,
            type: isHeic ? "heic" : files[0].type,
            id: res.data.fileID,
          });
        } catch (e) {
          this.photosStatus = {
            ...this.photosStatus,
            error: "error",
          };
          console.log(e);
        }
        this.photosStatus.isLoad = false;
      }
    },
    async deleteImage(id) {
      try {
        await API.reviews.deleteImageComment(
          this.review.Id,
          this.tempId,
          id,
          this.roleGroup === "biz" ? "business" : "client"
        );
        this.photos = this.photos.filter((el) => el.id !== id);
      } catch (e) {
        console.log(e);
      }
    },
    async deleteReviewComment(id) {
      try {
        await this.$store.dispatch("deleteItemComment", {
          value: {
            ID: id,
            parentID: this.review.Id,
          },
        });
        this.comments = this.comments.filter((el) => el.Id !== id);
      } catch (e) {
        console.log(e);
      }
    },
    deleteReview() {
      this.$store.dispatch("deleteItem", {
        value: {
          ID: this.review.Id,
        },
      });
    },
    async likeToggle() {
      if (!this.userID) {
        return;
      }
      this.$store.dispatch("reviewToggleLike", {
        value: {
          clientId: this.$store.state.general.userInfo.id,
          Id: this.review.Id,
        },
      });
    },
    async likeToggleComment(Id) {
      if (!this.userID) {
        return;
      }
      this.$store.dispatch("reviewToggleLikeComment", {
        value: {
          clientId: this.$store.state.general.userInfo.id,
          Id,
          parentID: this.review.Id,
        },
      });
    },
  },
  async mounted() {
    try {
      let res;
      if (this.isAuth) {
        res = await API.reviews.getImages(this.review.Id);
      } else {
        res = await API_WITHOUT_GUARDS.reviews.getImages(this.review.Id);
      }

      this.images = res.data?.photos || [];
      this.catLogo = res.data?.logo?.url || "";
    } catch (e) {
      console.log(e);
    }

    try {
      const avatar = await API_WITHOUT_GUARDS.user.getAvatar(
        this.review.authorID
      );
      this.avatar = avatar.data;
    } catch (e) {
      this.avatar = null;
      console.log(e);
    }

    if (!this.review.comments) {
      return;
    }

    let comments = [...this.review.comments];

    for (let i = 0; i < comments.length; i++) {
      try {
        let images;
        if (this.isAuth) {
          images = await API.reviews.getCommentPhotos(
            this.review.Id,
            comments[i].Id
          );
        } else {
          images = await API_WITHOUT_GUARDS.reviews.getCommentPhotos(
            this.review.Id,
            comments[i].Id
          );
        }

        if (images.data.length) {
          comments[i].images = images.data.map((el) => el.url);
        } else {
          comments[i].images = [];
        }
      } catch (e) {
        console.log(e);
      }
    }

    this.comments = comments;
  },

  computed: {
    isAuth() {
      return this.$store.state.general.auth.isAuth;
    },
    role() {
      return this.$store.getters.role;
    },
    roleGroup() {
      return this.$store.getters.roleGroup;
    },
    isResponse() {
      if (this.major || this.isClientList) {
        return true;
      } else {
        return this.review.authorID === this.userID;
      }
    },
    userID() {
      return this.$store.state.general.userInfo.id;
    },
    shownComments() {
      if (this.hide) {
        return [this.comments[this.comments.length - 1]];
      } else {
        return this.comments;
      }
    },
  },

  watch: {
    async isEdit(val) {
      if (val) {
        try {
          const res = await API.reviews.getId();
          this.tempId = res.data;
          this.photos = [];
        } catch (e) {
          console.log(e);
        }
        this.$refs.block.scrollIntoView(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.review {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  &._clientList {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
  }

  &-author {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &-name {
      @include mobile {
        width: 80px;
      }
    }

    &-avatar {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      width: 32px;
      height: 32px;
      margin-right: 8px;
      border-radius: 50%;

      img {
        flex: 0 0 auto;
        width: 32px;
        height: 32px;
        margin-right: 8px;
        border-radius: 50%;
        background-color: white;
      }
    }

    &._comment {
      margin-bottom: 8px;

      .review-author-avatar {
        display: flex;
        align-items: center;
        width: 32px;
        height: 32px;
        margin-right: 8px;
        border-radius: 50%;

        img {
          flex: 0 0 auto;
          width: 32px;
          height: 32px;
          margin-right: 8px;
          border-radius: 50%;
          background-color: white;
        }
      }
    }
  }

  &-rating {
    margin-bottom: 8px;
  }

  &-catName {
    font-weight: 600;
    margin-bottom: 4px;
    overflow-wrap: break-word;
  }

  &-description {
    margin-bottom: 16px;
    overflow-wrap: break-word;
    white-space: pre-line;
  }

  &-images {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &._major {
      margin-top: 16px;
    }
  }

  &-image {
    width: 116px;
    height: 80px;
    border-radius: 8px;
    cursor: pointer;
  }

  &-delete-icon {
    cursor: pointer;
  }

  &-comment {
    position: relative;
    margin-top: 16px;
    padding: 20px 46px 16px 56px;

    @include mobile {
      padding: 16px;
    }

    &._map {
      padding: 20px 16px 16px 24px;
    }

    p {
      word-wrap: break-word;
    }
  }

  &-icons {
    display: flex;
    align-items: center;
    margin-top: 16px;
    user-select: none;

    span {
      margin-left: 4px;
      color: #858585;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      cursor: auto;
    }

    .response {
      margin-left: 16px;
      color: #f18e35;
      cursor: pointer;
    }
  }
}

.like {
  cursor: pointer;
}

.comment-icon {
  margin-left: 18px;
  cursor: auto;
}

.text {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  .textarea {
    width: 100%;
    height: 140px;
    margin-bottom: 12px;
  }

  span:first-child {
    margin-bottom: 12px;
  }

  span:last-child {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #858585;
  }
}

.photos-alert {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #858585;
}

.photos {
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  &-img {
    width: 114px;
    height: 75px;
    border-radius: 8px;

    &._heic {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #858585;
      }

      svg {
        margin-bottom: 6px;
      }
    }
  }

  &-alert {
    margin-top: 8px;
    color: #858585;
  }
}

.btns {
  display: flex;
  margin-top: 16px;

  .btn {
    margin-left: auto;
  }
}

.menu-rev {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  right: -6px;
  top: -8px;
  display: flex;
  flex-direction: column;
  width: 310px;
  padding: 16px 12px 16px 16px;
  background: #fff;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;

  span {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #222;
    cursor: pointer;
  }

  span:last-child {
    color: #f03738;
    margin-bottom: 0;
  }

  svg {
    position: absolute;
    right: 22px;
    top: 20px;
  }
}

.input-label {
  width: 190px;
  margin-top: 16px;
  margin-bottom: 8px;
}

.addBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #f18e35;
  height: 32px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;

  span {
    margin-left: 4px;
    color: #f18e35;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}

.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.logo {
  width: 32px;
  height: 32px;
  border-radius: 50%;

  &._default {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #ebebeb;
    background-size: 26px 26px;
    background-repeat: no-repeat;
    filter: grayscale(1);
  }
}

.stars {
  display: flex;
}

.date {
  display: block;
  margin-left: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #858585;

  &._comment {
    margin-left: auto;
  }
}

.moderator-name {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.show-comment {
  display: flex;
  padding-top: 12px;
  margin-top: 24px;
  margin-bottom: 16px;
  justify-content: center;
  border-top: 1px solid #d6d6d6;
  cursor: pointer;

  color: #adadad;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.btns {
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @include mobile {
    button {
      width: calc(50% - 8px);
    }
  }

  .btn {
    width: 100%;
    margin: 0;

    &._map {
      width: 140px;
    }

    &._send {
      width: auto;
      margin-left: auto;
    }
  }
}

.comment-text {
  white-space: pre-line;
}

.icons {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: auto;
}
</style>
