<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.22262 6.29753C4.9258 6.00071 4.9258 5.51945 5.22262 5.22262C5.51945 4.9258 6.00071 4.9258 6.29753 5.22262L14.7774 13.7025C15.0742 13.9993 15.0742 14.4805 14.7774 14.7774C14.4805 15.0742 13.9993 15.0742 13.7025 14.7774L5.22262 6.29753Z"
      fill="#222222"
    />
    <path
      d="M13.7025 5.22262C13.9993 4.92579 14.4805 4.92579 14.7774 5.22262C15.0742 5.51945 15.0742 6.0007 14.7774 6.29753L6.29753 14.7774C6.0007 15.0742 5.51945 15.0742 5.22262 14.7774C4.92579 14.4805 4.92579 13.9993 5.22262 13.7025L13.7025 5.22262Z"
      fill="#222222"
    />
  </svg>
</template>

<script>
export default {
  name: "IconCross",
};
</script>

<style scoped></style>
