<template>
  <div class="inner">
    <div class="wrapper">
      <div class="header">
        <div class="title">Новый отзыв</div>
        <div class="main-info">
          <VImage
            v-if="logo"
            :src="`${url}files/cat/${clickedCatering}/logo`"
            class="main-info-logo"
          />
          <div v-else class="main-info-default-logo">
            <img src="../../assets/images/logo.png" :alt="details.name" />
          </div>
          <div class="main-info-title">
            <p class="main-info-title-name">{{ details.name }}</p>
            <p class="main-info-title-type">
              {{ getType(details.cfTypeId) }}
            </p>
          </div>
        </div>
      </div>

      <div class="blocks">
        <div class="block_left">
          <div class="rating">
            <span>Ваша оценка</span>
            <InputStar v-model:value="rating" />
          </div>
          <div class="text">
            <span>Отзыв</span>
            <VTextArea
              v-model:value="description"
              class="textarea"
              placeholder="Напишите, ваши впечатления о заведении"
              is-clear
            />
            <span>Минимум 100 символов</span>
          </div>
        </div>
        <div class="block_right">
          <div class="photos">
            <input
              :style="{ display: 'none' }"
              type="file"
              id="photos"
              @change="uploadImage($event, 'photos')"
              accept="image/*,.heic,.heif"
            />
            <div class="container">
              <ImageLoadLabel
                v-if="photosStatus.isLoad && photos.length < 2"
                type="load"
                forId="photos"
                class="photos-img"
              />
              <ImageLoadLabel
                v-else-if="photosStatus.error && photos.length < 2"
                :type="photosStatus.error"
                forId="photos"
                class="photos-img"
              />
              <label
                class="hiddenLabel"
                for="photos"
                v-else-if="photos.length === 0"
              >
                <div class="addBtn">
                  <IconPlus color="#f18e35" />
                  <span> Добавить фотографию </span>
                </div>
              </label>
              <div
                v-else-if="photos.length > 0 && photos.length < 3"
                class="photos-img"
              >
                <ImageLoadLabel type="add" forId="photos" class="photos-img" />
              </div>
              <template v-for="photo in photos" :key="photo.fileID">
                <div v-if="photo.type === 'heic'" class="photos-img _heic">
                  <IconInvalidType />
                  <span>Для файлов heic предпросмотр недоступен</span>
                </div>
                <VImage
                  v-else
                  class="photos-img"
                  :src="photo.url"
                  is-deletable
                  @image-deleted="deleteImage(photo.id)"
                />
              </template>
            </div>
            <p class="photos-alert">
              Загрузите до 3 фотографий JPG или PNG до 6 МБ каждая
            </p>
          </div>

          <VButton
            class="btn"
            @click="send"
            :disabled="description.length < 100"
            >Опубликовать</VButton
          >
        </div>
      </div>
    </div>
    <IconPopupCross
      v-if="screenWidth !== 'mobile'"
      class="modal-cross"
      @click="$store.dispatch('closeModal')"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const screenWidth = computed(() => {
  return store.getters.screenType;
});
</script>

<script>
import { RESTAURANT_TYPES } from "@/assets/js/utils/consts";
import VTextArea from "@/components/ui/VTextArea.vue";
import VButton from "@/components/ui/VButton.vue";
import InputStar from "@/components/ui/InputStar.vue";

import { API_WITHOUT_GUARDS } from "@/assets/js/api/apiWithoutGuards";
import { API } from "@/assets/js/api/api";
import VImage from "@/components/ui/VImage.vue";
import ImageLoadLabel from "@/components/ui/ImageLoadLabel.vue";
import { prodCheck } from "@/assets/js/utils/prodCheck";
import { BASE_URL } from "@/assets/js/api/baseUrl";
import IconInvalidType from "@/components/icons/IconInvalidType.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import ReviewSuccessModal from "@/components/modals/ReviewSuccessModal.vue";
import ReviewRejectModal from "@/components/modals/ReviewRejectModal.vue";
import IconPopupCross from "@/components/icons/IconPopupCross.vue";

export default {
  name: "ReviewFormModal",
  components: {
    IconPopupCross,
    IconPlus,
    IconInvalidType,
    VTextArea,
    VButton,
    InputStar,
    ImageLoadLabel,
    VImage,
  },
  data() {
    return {
      isLoaded: true,
      details: {},
      description: "",
      rating: 0,
      tempId: null,
      photosStatus: {
        isLoad: false,
        error: "",
      },
      photos: [],
      url: "",
      logo: "",
    };
  },

  methods: {
    async uploadImage(event) {
      const { files } = event.target;
      if (files && files[0]) {
        const blob = URL.createObjectURL(files[0]);
        const formData = new FormData();
        const isInvalidSize = files[0].size > 6291456;

        const ValidImageTypes = [
          "image/jpg",
          "image/jpeg",
          "image/png",
          "image/gif",
          "image/bmp",
        ];

        const isValidType = ValidImageTypes.includes(files[0].type);

        const isHeic = files[0].name.includes(".heic");

        formData.append("file", files[0]);
        formData.append("reviewid", this.id || this.tempId);
        try {
          this.photosStatus.error = "";
          this.photosStatus.isLoad = true;

          if (!isValidType && !isHeic) {
            this.photosStatus.error = "invalid";
            this.photosStatus.isLoad = false;
            return;
          }

          if (isInvalidSize) {
            this.photosStatus.error = "invalid";
            this.photosStatus.isLoad = false;
            return;
          }

          const res = await API.reviews.uploadImage(
            this.id || this.tempId,
            formData
          );
          this.photos.push({
            url: blob,
            type: isHeic ? "heic" : files[0].type,
            id: res.data.fileID,
          });
        } catch (e) {
          this.photosStatus = {
            ...this.photosStatus,
            error: "error",
          };
          console.log(e);
        }
        this.photosStatus.isLoad = false;
      }
    },
    async deleteImage(id) {
      try {
        await API.reviews.deleteImage(this.tempId, id);
        this.photos = this.photos.filter((el) => el.id !== id);
      } catch (e) {
        console.log(e);
      }
    },
    getType(id) {
      return RESTAURANT_TYPES[id];
    },
    clickOutside() {
      this.isLoaded = true;
    },
    send() {
      if (this.rating) {
        try {
          this.$store.dispatch("createItem", {
            value: {
              authorID: this.$store.state.general.userInfo.id,
              authorName: `${this.$store.state.general.userInfo.firstName} ${this.$store.state.general.userInfo.lastName}`,
              authorRole: 4,
              cateringID: this.details.id,
              ID: this.tempId,
              star: this.rating,
              text: this.description,
              fileIDs: this.photos.map((el) => el.id),
            },
          });
          this.isLoaded = false;

          this.$store.dispatch("openModal", {
            value: {
              component: ReviewSuccessModal,
            },
          });
        } catch (err) {
          console.log(err);
        }
      } else {
        this.$store.dispatch("openModal", {
          value: {
            component: ReviewRejectModal,
          },
        });
      }
    },
  },

  computed: {
    clickedCatering() {
      return this.$route.params.catId;
    },
  },

  async mounted() {
    window.scrollTo(0, 0);
    this.isLoaded = false;
    const res = await API_WITHOUT_GUARDS.client.getCateringByID(
      this.clickedCatering
    );
    this.details = res.data;
    const resId = await API.reviews.getId();
    this.tempId = resId.data;
    this.isLoaded = true;

    this.url = prodCheck() ? window.location.origin + "/api/v1/" : BASE_URL;

    try {
      const images = await API_WITHOUT_GUARDS.catering.getLogoByEviso(
        this.clickedCatering
      );
      this.logo = images.data;
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
}

.title {
  padding: 24px 16px 12px 0;
  font-family: $font-family-secondary;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;

  @include mobile {
    font-size: 24px;
    line-height: 32px;
  }
}

.main-info {
  display: flex;
  align-items: flex-start;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #222;

  &-price {
    margin-left: auto;
  }

  &-title {
    overflow-wrap: break-word;

    &-name {
      font-family: $font-family-secondary;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 40px;

      @include mobile {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
      }
    }

    &-type {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #858585;
    }
  }

  &-default-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    width: 62px;
    height: 62px;
    margin-right: 16px;
    border-radius: 50%;
    filter: grayscale(1);
    background: #ebebeb;

    img {
      width: 40px;
      height: 40px;
    }
  }

  &-logo {
    flex: 0 0 auto;
    width: 62px;
    height: 62px;
    margin-right: 16px;
    border-radius: 50%;
  }
}

.rating {
  display: flex;
  flex-direction: column;
  padding: 24px 16px 16px 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  span {
    margin-bottom: 2px;
  }
}

.text {
  display: flex;
  flex-direction: column;
  padding: 24px 16px 16px 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  @include mobile {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
  }

  .textarea {
    width: 360px;
    height: 208px;
    margin-bottom: 12px;

    @include mobile {
      width: 100%;
    }
  }

  span:first-child {
    margin-bottom: 12px;
  }

  span:last-child {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #858585;
  }
}

.btn {
  margin-top: auto;
  margin-bottom: 0;
  padding: 12px 20px;
  width: 368px;
  height: 52px;
  border-radius: 12px;
}

.photos {
  display: flex;
  flex-direction: column;
  padding: 0 16px 24px 16px;

  &-img {
    width: 112px;
    height: 80px;
    border-radius: 8px;

    @include mobile {
      width: 104px;
    }

    &._heic {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #858585;
      }

      svg {
        margin-bottom: 6px;
      }
    }
  }

  &-alert {
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #858585;
  }
}

.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @include mobile {
    gap: 8px;
  }

  label {
    width: 112px;
    height: 80px;
    border-radius: 8px;

    @include mobile {
      width: 104px;
    }
  }

  .hiddenLabel {
    height: 32px;
  }
}

.addBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 190px;
  height: 32px;
  border-radius: 8px;
  border: 2px solid #f18e35;
  cursor: pointer;

  span {
    margin-left: 4px;
    color: #f18e35;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 16px 32px 0;
  border-bottom: 1px solid #d6d6d6;
}

.blocks {
  display: flex;
}

.block_left {
  border-right: 1px solid #d6d6d6;
}

.block_right {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 448px;
  margin: auto;
  padding: 32px 56px 40px 56px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  @media screen and (max-width: 841px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px 0 #0000000d;
    border-radius: 20px 20px 0 0;
    padding: 32px 24px 24px 24px;
  }
}

.modal-cross {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}
</style>
