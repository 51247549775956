<template>
  <div class="inner">
    <div class="main-block">
      <div class="lines">
        <div class="line _active" />
        <div class="line _active" />
        <div class="line _active" />
        <div class="line _active" />
      </div>
      <span class="title">Выбор времени</span>
      <TimeSlots
        v-if="isLoaded"
        :timeSlots="timeSlots"
        :currentHour="currentHour"
        :currentMinute="currentMinute"
        @isDateSlot="$emit('isDateSlot', $event)"
        @updateHour="currentHour = $event"
        @updateMinute="currentMinute = $event"
      />
    </div>
    <div class="buttons">
      <VButton outline @click="onPrevClickHandler" class="button">
        Назад
      </VButton>
      <VButton @click="onNextClickHandler" class="button"> Сохранить </VButton>
    </div>
    <IconPopupCross
      v-if="screenWidth !== 'mobile'"
      class="modal-cross"
      @click="$store.dispatch('closeModal')"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import IconPopupCross from "@/components/icons/IconPopupCross.vue";
import TimeSlots from "@/components/pages/w/TimeSlots.vue";

const store = useStore();

const screenWidth = computed(() => {
  return store.getters.screenType;
});
</script>

<script>
import VButton from "@/components/ui/VButton.vue";
import FilterDurationModal from "@/components/modals/FilterDurationModal.vue";
export default {
  name: "FilterTimeModal",

  components: {
    VButton,
  },

  data() {
    return {
      currentHour: "00-00",
      currentMinute: 0,
      timeSlots: {
        "2024-7-25": {
          "00-00": [1, 1, 1, 1, 1, 1],
          "01-00": [1, 1, 1, 1, 1, 1],
          "02-00": [1, 1, 1, 1, 1, 1],
          "03-00": [1, 1, 1, 1, 1, 1],
          "04-00": [1, 1, 1, 1, 1, 1],
          "05-00": [1, 1, 1, 1, 1, 1],
          "06-00": [1, 1, 1, 1, 1, 1],
          "07-00": [1, 1, 1, 1, 1, 1],
          "08-00": [1, 1, 1, 1, 1, 1],
          "09-00": [1, 1, 1, 1, 1, 1],
          "10-00": [1, 1, 1, 1, 1, 1],
          "11-00": [1, 1, 1, 1, 1, 1],
          "12-00": [1, 1, 1, 1, 1, 1],
          "13-00": [1, 1, 1, 1, 1, 1],
          "14-00": [1, 1, 1, 1, 1, 1],
          "15-00": [1, 1, 1, 1, 1, 1],
          "16-00": [1, 1, 1, 1, 1, 1],
          "17-00": [1, 1, 1, 1, 1, 1],
          "18-00": [1, 1, 1, 1, 1, 1],
          "19-00": [1, 1, 1, 1, 1, 1],
          "20-00": [1, 1, 1, 1, 1, 1],
          "21-00": [1, 1, 1, 1, 1, 1],
          "22-00": [1, 1, 1, 1, 1, 1],
          "23-00": [1, 1, 1, 1, 1, 1],
        },
      },
      isLoaded: false,
    };
  },

  methods: {
    onPrevClickHandler() {
      this.$store.commit({
        type: "setFilterTime",
        value: {
          hours: this.currentHour.slice(0, 2),
          minutes: this.currentMinute * 10,
        },
      });
      this.$store.dispatch("openModal", {
        value: {
          component: FilterDurationModal,
          props: {},
        },
      });
    },
    async onNextClickHandler() {
      this.$store.commit({
        type: "setFilterTime",
        value: {
          hours: this.currentHour.slice(0, 2),
          minutes: this.currentMinute * 10,
        },
      });
      this.$store.dispatch("closeModal");
    },
  },

  watch: {
    currentVisitorsCount(val) {
      this.$emit("changeVisitorsCount", val);
    },
  },

  mounted() {
    this.currentHour = `${
      this.$store.state.clientRestaurants.filter.time?.hours || "00"
    }-00`;
    this.currentMinute = this.$store.state.clientRestaurants.filter.time
      ?.minutes
      ? this.$store.state.clientRestaurants.filter.time.minutes / 10
      : 0;
    this.isLoaded = true;
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin: 32px 0 0;
}

.title {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #222222;
}

.main-block {
  padding-bottom: 48px;

  @include mobile {
    padding: 64px;
  }
}

.message {
  width: 336px;
  margin-top: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #222222;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  width: 100%;

  @include mobile {
    flex-direction: column;
    margin-top: 32px;
  }
}

.button {
  height: 40px;
  padding: 9px 38px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  @include mobile {
    &:first-child {
      margin-bottom: 16px;
    }
  }
}
</style>

<style lang="scss" scoped>
.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 776px;
  width: 640px;
  max-width: 640px;
  margin: auto;
  padding: 32px 56px 40px 56px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  @media screen and (max-width: 841px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px 0 #0000000d;
    border-radius: 20px 20px 0 0;
    padding: 32px 24px 24px 24px;
  }
}

h2 {
  margin-bottom: 144px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.modal-cross {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.timers {
  max-width: 776px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  > div {
    width: calc(50% - 8px);
  }
}

.lines {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 128px;

  @include mobile {
    width: calc(100% - 32px);
    height: 8px;
    justify-content: flex-start;
    align-items: center;
    margin: 0 16px;
    padding: 0 4px;
    border-radius: 8px;
    background-color: white;
  }
}

.line {
  width: 204px;
  height: 8px;
  background-color: #adadad;

  @include mobile {
    width: calc(20% - 4px);
    border-radius: 8px;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }

  &._active {
    background-color: #f18e35;
    cursor: pointer;
  }
}

.title {
  position: absolute;
  top: 124px;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #222222;

  @include mobile {
    font-size: 20px;
    line-height: 24px;
  }
}
</style>
