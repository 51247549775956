import axios from "axios";
import { prodCheck } from "@/assets/js/utils/prodCheck";
import { BASE_URL_2 } from "@/assets/js/api/baseUrl";
import { memoizedRefreshToken } from "@/assets/js/utils/refreshToken";

const instance1 = axios.create({
  baseURL: prodCheck() ? "/api/v2/" : BASE_URL_2,
  withCredentials: true,
});

instance1.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] =
      "Bearer " + localStorage.getItem("accessToken");
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance1.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const result = await memoizedRefreshToken();

      if (result?.accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${result?.accessToken}`,
        };
      }

      return axios(config);
    }
    return Promise.reject(error);
  }
);

export const LongPolling = {
  notices: {
    getNotifications(userId, realm) {
      return instance1.get(`${realm}/notification/${userId}`);
    },
  },
};
